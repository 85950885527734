import { Injectable, inject } from "@angular/core";

import { Observable } from "rxjs";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import {
    AUTHORIZATION_API_ACCESS,
    AUTHORIZATION_API_USER,
    SharedConfigService,
} from "@codman/shared/util-logex-framework-setup";

import type {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    OrganizationIdentifier,
    AuthorizationPermission,
    AuthorizationProfile,
} from "./data-access-authorization-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessAuthorizationApiService extends ServerGatewayBase {
    _configService = inject(SharedConfigService);

    constructor() {
        super();
        const apiUrl = this._configService.getConfiguration()?.authApiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set Authorization API url based on configuration.");
        }
    }

    /**
     * User information
     */
    getOrganizations(
        params: { applicationInstance?: string } = {},
    ): Observable<AuthorizationOrganization[]> {
        return this._get<AuthorizationOrganization[]>(`${AUTHORIZATION_API_USER}/organizations`, {
            params,
        });
    }

    getPermissions(
        params: {
            applicationInstance?: string;
            product?: string;
            organizationIdentifier?: number;
            organizationType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationPermission[]> {
        return this._get<AuthorizationPermission[]>(`${AUTHORIZATION_API_USER}/permissions`, {
            params,
        });
    }

    getProfileExistence(): Observable<boolean> {
        return this._get<boolean>(`api/v4/user/profile-exists`);
    }

    /**
     * Consider using CodmanUserAuthorizationService.searchCurrentOrganizationPermissions for any permission for the current organization
     * The mentioned method caches the response and allows faster access to the permissions
     */
    searchPermissions(
        params: {
            applicationInstances?: string[];
            products?: string[];
            organizations?: string[];
            organizationIdentifierType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationExtendedPermission[]> {
        params.applicationInstances = params.applicationInstances ?? [];
        params.products = params.products ?? [];
        params.organizations = params.organizations ?? [];
        params.organizationIdentifierType = params.organizationIdentifierType ?? "Cic";

        return this._postQuery<AuthorizationExtendedPermission[]>(
            `${AUTHORIZATION_API_USER}/permissions/search`,
            {
                body: { ...params },
            },
        );
    }

    getProfile(): Observable<AuthorizationProfile> {
        return this._get<AuthorizationProfile>(`${AUTHORIZATION_API_USER}/profile`);
    }

    /**
     * Access checks
     */
    checkOrganization(params: {
        organizationIdentifier: string;
        organizationType: OrganizationIdentifier;
    }): Observable<boolean> {
        return this._get<boolean>(`${AUTHORIZATION_API_ACCESS}/organization`, { params });
    }
}
