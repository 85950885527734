import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { LgColorTypes, LgOwnColorConfiguration } from "@logex/framework/lg-charts";
import { LgButton } from "@logex/framework/ui-core";

@Component({
    selector: "codman-ui-chart-button",
    templateUrl: "./ui-chart-button.component.html",
    styleUrls: ["./ui-chart-button.component.scss"],
})
export class UiChartButtonComponent extends LgButton implements OnInit, OnChanges {
    @Input() chartColor: string | null = null;
    @Input() usePieChart = false;
    @Input() denominator? = 0;
    @Input() numerator? = 0;

    readonly LgColorTypes = LgColorTypes;

    _colorConfiguration: LgOwnColorConfiguration = {
        colorType: LgColorTypes.Own,
        colors: [],
    };

    ngOnInit(): void {
        this._updateColorConfiguration();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._updateColorConfiguration();
    }

    _updateColorConfiguration(): void {
        this._colorConfiguration = {
            colorType: LgColorTypes.Own,
            colors: [this.chartColor ?? ""],
        };
    }
}
