import { ConnectedOverlayPositionChange, ConnectedPosition, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { ElementRef } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IOverlayResultApi, LgOverlayService } from "@logex/framework/ui-core";

export interface IOverlayPopup<InitProps, Result> {
    initialize: (config: InitProps) => Observable<Result>;
    _updatePosition: (position: ConnectedOverlayPositionChange) => void;
}

export type PopupPositions =
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
    | "top-left"
    | "top-right";

const POPUP_POSITIONS: { [name in PopupPositions]: ConnectedPosition } = {
    "bottom-left": { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
    "bottom-center": { originX: "center", originY: "bottom", overlayX: "center", overlayY: "top" },
    "bottom-right": { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
    "top-left": { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
    "top-right": { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" },
};

export abstract class OverlayBase<InitProps, Result> {
    _popupActive = false;

    private _overlayInstance: IOverlayResultApi | null = null;
    private _popupInstance: IOverlayPopup<InitProps, Result> | null = null;
    protected _popupHidden$: Subject<void> | null = null;

    constructor(
        private _elementRef: ElementRef,
        private _scrollDispatcher: ScrollDispatcher,
        private _overlayService: LgOverlayService,
        private _overlay: Overlay,
        protected _popupComponentClass: new (...args: any[]) => IOverlayPopup<InitProps, Result>,
    ) { }

    protected _onDestroy(): void {
        this._hidePopup();
    }

    protected _showPopup(
        popupInitProps: InitProps,
        config?: {
            positions?: PopupPositions[];
            overlayClick?: () => void;
        },
    ): Observable<Result> {
        this._popupHidden$ = new Subject<void>();

        let popupPositions = config?.positions;
        if (popupPositions == null || popupPositions?.length === 0) {
            popupPositions = ["bottom-right", "bottom-left", "top-left", "top-right"];
        }

        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions(popupPositions.map(position => POPUP_POSITIONS[position]));

        positionStrategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef),
        );

        this._overlayInstance = this._overlayService.show({
            onClick: config?.overlayClick ?? (() => this._hidePopup()),
            hasBackdrop: true,
            sourceElement: this._elementRef,
            positionStrategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 }),
        });

        const portal = new ComponentPortal(this._popupComponentClass);
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        positionStrategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance?._updatePosition(change);
        });

        const result$ = this._popupInstance
            .initialize(popupInitProps)
            .pipe(takeUntil(this._popupHidden$));

        this._popupActive = true;

        return result$;
    }

    protected _hidePopup(): void {
        if (!this._popupActive) return;

        this._popupActive = false;
        this._popupHidden$?.next();
        this._popupHidden$?.complete();

        this._overlayInstance?.hide();

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;
    }
}
