// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-button {
  display: flex;
}

.chart-button__chart {
  width: 12px;
  height: 12px;
  margin-right: var(--spacing2);
  border: 4px solid black;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/feature-benchmarking/src/lib/ui-chart-button/ui-chart-button.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,6BAAA;EACA,uBAAA;EACA,kBAAA;AADJ","sourcesContent":["@use \"definitions\" as *;\n\n.chart-button {\n    display: flex;\n}\n\n.chart-button__chart {\n    width: 12px;\n    height: 12px;\n    margin-right: var(--spacing2);\n    border: 4px solid black;\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
