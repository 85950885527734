import { RegistryTenant } from "@codman/shared/assets";

import { TENANT_LOOKUP } from "./tenant";

export function parseProductCode(
    productCode: string,
): { tenant: RegistryTenant; id: string } | null {
    const productCodes = productCode.split(".");

    if (productCodes[0] !== "Product") {
        return null;
    }

    const registryId = productCodes[1];
    const tenant = TENANT_LOOKUP[registryId];

    return tenant != null
        ? {
              tenant,
              id: registryId,
          }
        : null;
}

export function createProductCode(registryId: string): string {
    if (TENANT_LOOKUP[registryId] == null) {
        console.warn("Creating product code for unknown registry.");
    }
    return `Product.${registryId}`;
}
