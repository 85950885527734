// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-item-selector {
  margin-top: 0;
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  align-items: stretch;
  border-radius: var(--border-radius);
  border: 1px solid rgb(var(--gray-color-20));
}
.single-item-selector:hover {
  border-color: rgb(var(--gray-color-40));
}

.single-item-selector__header {
  border-bottom: 1px solid rgb(var(--gray-color-10));
  background-color: rgb(var(--gray-color-2));
  box-sizing: border-box;
  height: 29px;
  line-height: 28px;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding-left: var(--spacing3);
}
.single-item-selector__header .single-item-selector__header--left {
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.single-item-selector__content .single-item-selector__item {
  display: flex;
  padding-left: var(--spacing3);
}

.lg-styled-radio-text {
  padding-left: var(--spacing2);
}`, "",{"version":3,"sources":["webpack://./libs/shared/ui-single-item-selector/src/lib/ui-single-item-selector/ui-single-item-selector.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,aAAA;EACA,iBAAA;EACA,yBAAA;EACA,oBAAA;EACA,mCAAA;EACA,2CAAA;AADJ;AAGI;EACI,uCAAA;AADR;;AAKA;EACI,kDAAA;EACA,0CAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;EACA,6BAAA;AAFJ;AAII;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,iBAAA;AAFR;;AAOI;EACI,aAAA;EACA,6BAAA;AAJR;;AAQA;EACI,6BAAA;AALJ","sourcesContent":["@use \"definitions\" as *;\n\n.single-item-selector {\n    margin-top: 0;\n    display: flex;\n    flex-flow: column;\n    align-content: flex-start;\n    align-items: stretch;\n    border-radius: var(--border-radius);\n    border: 1px solid lg-color(--gray-color-20);\n\n    &:hover {\n        border-color: lg-color(--gray-color-40);\n    }\n}\n\n.single-item-selector__header {\n    border-bottom: 1px solid lg-color(--gray-color-10);\n    background-color: lg-color(--gray-color-2);\n    box-sizing: border-box;\n    height: 29px;\n    line-height: 28px;\n    display: flex;\n    flex-wrap: nowrap;\n    flex-shrink: 0;\n    padding-left: var(--spacing3);\n\n    .single-item-selector__header--left {\n        flex-shrink: 1;\n        flex-basis: auto;\n        min-width: 28px;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        font-weight: bold;\n    }\n}\n\n.single-item-selector__content {\n    .single-item-selector__item {\n        display: flex;\n        padding-left: var(--spacing3);\n    }\n}\n\n.lg-styled-radio-text {\n    padding-left: var(--spacing2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
