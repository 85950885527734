import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { distinctUntilChanged, Observable, Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RegistryRedirectService {
    private _router = inject(Router);

    readonly defaultRegistry = "Dato";

    private _registrySubscription?: Subscription;

    private readonly _lastRegistryKey = "lastVisitedRegistry";

    observeLastVisitedRegistry(registry$: Observable<string>): void {
        this._registrySubscription?.unsubscribe();
        this._registrySubscription = registry$.pipe(distinctUntilChanged()).subscribe(registry => {
            localStorage.setItem(this._lastRegistryKey, registry);
        });
    }

    redirect(): void {
        const lastVisitedRegistry =
            localStorage.getItem(this._lastRegistryKey) ?? this.defaultRegistry;
        this._router.navigate([lastVisitedRegistry], { replaceUrl: true });
    }
}
