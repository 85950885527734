import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LG_FILTERSET_RENDERER_FACTORIES } from "@logex/framework/lg-filterset";
import { TimeRangeFilterComponent } from "./time-range-filter/time-range-filter.component";
import {
    TimeRangeFilterRendererFactory,
    TimeRangeFilterRendererLineComponent,
} from "./time-range-filter/time-range-filter-renderer";
import { SharedTimeRangePickerComponent } from "./time-range-picker/time-range-picker.component";
import { SharedTimeRangeFilterInputComponent } from "./time-range-filter-input/time-range-filter-input.component";

@NgModule({
    imports: [
        CommonModule,
        SharedTimeRangeFilterInputComponent,
        UiCoreModule,
        LgLayoutModule,
        LgLocalizationModule,
        SharedTimeRangePickerComponent,
    ],
    declarations: [TimeRangeFilterComponent, TimeRangeFilterRendererLineComponent],
    exports: [TimeRangeFilterComponent],
    providers: [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: TimeRangeFilterRendererFactory,
            multi: true,
        },
    ],
})
export class SharedTimeRangeFilterModule {}
