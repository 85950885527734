import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { capitalize, Dictionary, keyBy, sortBy } from "lodash";

import { IDropdownDefinition, LgItemSelectorConfiguration } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

import { IOverlayPopup } from "@codman/shared/ui-overlay";
import { ISingleSelectorItem } from "@codman/shared/ui-single-item-selector";

import {
    Benchmark,
    BenchmarkType,
    IBenchmarkConfiguration,
    IConnectBenchmark,
    IConnectProvider,
    Regions,
} from "../feature-benchmarking.types";

export type IndicatorTransparency = "Internal" | "Obligatory" | "Transparent";
export type AllowedBenchmarks = {
    [key in BenchmarkType]?: boolean;
};

export interface ISelectorItem {
    id: number | string;
    name: string;
}

export interface IBenchmarkInitProps {
    connectProviders: IConnectProvider[];
    connectSelection?: number[];
    regionSelection?: Regions;
    providerTypeSelection?: string;
    allowedBenchmarks: AllowedBenchmarks;
    selectedBenchmark: BenchmarkType;
    bechmarkConfiguration: IBenchmarkConfiguration;
    shouldSelectOnlyOneRegion: boolean;
}

/**
 * @deprecated
 * use Benchmark
 */
export interface IBenchmarkSelection {
    selectedBenchmark: BenchmarkType;
    connectSelection?: number[];
    regionSelection?: Regions;
    providerTypeSelection?: string;
}

@Component({
    selector: "codman-ui-benchmark-selector-popup",
    templateUrl: "./ui-benchmark-selector-popup.component.html",
    styleUrls: ["./ui-benchmark-selector-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [useTranslationNamespace("APP._Shared.BenchmarkSelector")],
})
export class UiBenchmarkSelectorPopupComponent
    implements IOverlayPopup<IBenchmarkInitProps, Benchmark>
{
    private _lgTranslateService = inject(LgTranslateService);

    readonly _selectorConfig: LgItemSelectorConfiguration<ISelectorItem> = {
        isDisabled: () => false,
        isPartiallySelected: () => false,
        getName: (item: ISelectorItem) => item.name,
        getId: (item: ISelectorItem) => item.id,
        sortItems: providers => sortBy(providers, p => p.name),
    };

    readonly _regionSelectorConfig: LgItemSelectorConfiguration<ISelectorItem> = {
        isDisabled: () => false,
        isPartiallySelected: () => false,
        getId: region => region.id,
        sortItems: regions => sortBy(regions, r => r.name),
    };

    _selectorHeight = 0;

    _connectProviders: IConnectProvider[] = [];
    _connectSelection: Record<number, IConnectProvider> = {};
    _regionSelection: Dictionary<ISelectorItem> = {};
    _singleRegionSelection: string | undefined;
    _providerTypeSelection: string | undefined = undefined;
    _allowedBenchmarks = <AllowedBenchmarks>{};
    _selectedBenchmark: BenchmarkType = "national";
    _benchmarkTypes: IDropdownDefinition<BenchmarkType> = {};
    _availableRegions: ISelectorItem[] = [];
    _availableProviderTypes: ISingleSelectorItem[] = [];
    _shouldSelectOnlyOneRegion = false;

    private readonly _maximumNumberOfVisibleItems = 6;

    private _possibleBechnmarkTypes: BenchmarkType[] = [
        "national",
        "regional",
        "international",
        "connect",
        "providerType",
    ];

    private readonly _selection$ = new Subject<Benchmark>();

    initialize(config: IBenchmarkInitProps): Observable<Benchmark> {
        this._initializeConnect(config);
        this._initializeProviderTypes(config);
        this._initializeRegions(config);

        this._allowedBenchmarks = { ...config.allowedBenchmarks };

        if (this._allowedBenchmarks[config.selectedBenchmark]) {
            this._selectedBenchmark = config.selectedBenchmark;
        } else {
            const benchmark = Object.entries(this._allowedBenchmarks).find(
                ([_, isAllowed]) => isAllowed,
            );
            if (benchmark) {
                this._selectedBenchmark = <BenchmarkType>benchmark[0];
            }
        }

        this._possibleBechnmarkTypes = this._possibleBechnmarkTypes.filter(
            type =>
                this._allowedBenchmarks[type] &&
                (type !== "connect" || this._connectProviders.length) &&
                (type !== "providerType" ||
                    (this._availableProviderTypes.length && this._availableProviderTypes[0])) &&
                (type !== "regional" || this._availableRegions.length),
        );

        this._selectorHeight = this._calculateSelectorHeight();
        this._setBenchmarkTypes();
        return this._selection$;
    }

    _selectBenchmark(benchmarkType: BenchmarkType): void {
        this._selectedBenchmark = benchmarkType;
        this._selectorHeight = this._calculateSelectorHeight();
    }

    _update(): void {
        let selection: Benchmark | undefined;
        const regions = this._getRegionsSelection();
        if (this._selectedBenchmark === "connect") {
            selection = this._getConnectSelection(regions);
        } else if (this._selectedBenchmark === "regional") {
            if (regions)
                selection = {
                    type: "regional",
                    regions,
                };
            else selection = undefined;
        } else if (this._selectedBenchmark === "providerType") {
            if (regions)
                selection = this._providerTypeSelection
                    ? {
                          type: "providerType",
                          providerType: this._providerTypeSelection,
                          regions,
                      }
                    : undefined;
        } else {
            selection = {
                type: this._selectedBenchmark,
            };
            console.log("Deprecated benchmark selection picked!");
        }

        if (selection) {
            this._selection$.next(selection);
            this._selection$.complete();
        }
    }

    _updatePosition(position: ConnectedOverlayPositionChange): void {
        //
    }

    _cancel(): void {
        this._selection$.complete();
    }

    _updateRegionSingleSelection(regionSelection: string | number): void {
        this._singleRegionSelection = regionSelection.toString();
    }

    _selectProviderType(providerType: string | number): void {
        this._providerTypeSelection = providerType.toString();
    }

    private _calculateSelectorHeight(): number {
        const optionHeight = 24;
        const selectorHeaderHeight = 29;
        let numberOfDisplayedItems = 0;

        switch (this._selectedBenchmark) {
            case "connect":
                numberOfDisplayedItems = this._connectProviders.length;
                break;
            case "providerType":
                numberOfDisplayedItems = this._availableProviderTypes.length;
                break;
            case "regional":
                numberOfDisplayedItems = this._availableRegions.length;
                break;
        }

        return (
            selectorHeaderHeight +
            optionHeight * Math.min(numberOfDisplayedItems, this._maximumNumberOfVisibleItems)
        );
    }

    private _setBenchmarkTypes(): void {
        this._benchmarkTypes = {
            groupId: "benchmarks",
            groups: [
                {
                    entries: this._possibleBechnmarkTypes.map(i => ({
                        name: this._getBenchmarkLabel(i),
                        id: i,
                    })),
                },
            ],
        };
    }

    private _getBenchmarkLabel(_benchmarkType: BenchmarkType): string {
        if (
            _benchmarkType === "regional" &&
            this._availableRegions.length === 1 &&
            typeof this._availableRegions[0].id === "string"
        )
            return `${this._availableRegions[0].id.toUpperCase()} ${this._lgTranslateService.translate(
                ".Benchmark",
            )}`;

        return this._lgTranslateService.translate(
            `.Benchmarks.${capitalize(_benchmarkType)}.ShortLabel`,
        );
    }

    private _initializeConnect(config: IBenchmarkInitProps): void {
        this._connectProviders = config.connectProviders.sort((first, second) => {
            return first.name.localeCompare(second.name);
        });
        const connectProvidersLookup = keyBy(config.connectProviders, provider => provider.id);
        this._connectSelection =
            config.connectSelection?.reduce((selection, connectProviderId) => {
                const connectProvider = connectProvidersLookup[connectProviderId];
                if (connectProvider) {
                    selection[connectProviderId] = connectProvider;
                }
                return selection;
            }, <Record<number, IConnectProvider>>{}) ?? {};
    }

    private _initializeProviderTypes(config: IBenchmarkInitProps): void {
        const { providerTypeSelection, bechmarkConfiguration } = config;
        if (
            bechmarkConfiguration.defaultProviderType &&
            bechmarkConfiguration.providerTypes.some(
                providerType => providerType === bechmarkConfiguration.defaultProviderType,
            )
        ) {
            this._availableProviderTypes = bechmarkConfiguration.providerTypes.map(
                providerType => ({
                    id: providerType,
                    name: this._lgTranslateService.translate(
                        ".Benchmarks.Providertype.Providers." + providerType + ".FullName",
                    ),
                }),
            );
            this._providerTypeSelection = providerTypeSelection;
        } else {
            this._availableProviderTypes = [];
            this._providerTypeSelection = undefined;
        }
    }

    private _initializeRegions(config: IBenchmarkInitProps): void {
        this._availableRegions = config.bechmarkConfiguration.regions.map(region => ({
            id: region,
            name: this._translateRegionName(region),
        }));

        this._regionSelection = {};
        if (config.regionSelection == null) {
            return;
        }

        for (const region of config.regionSelection) {
            this._regionSelection[region] = {
                id: region,
                name: this._translateRegionName(region),
            };
        }
        if (this._singleRegionSelection == null) {
            const firstRegion = config.regionSelection[0];
            if (firstRegion) {
                this._singleRegionSelection = firstRegion;
            }
        }

        this._shouldSelectOnlyOneRegion = config.shouldSelectOnlyOneRegion;
    }

    private _translateRegionName(region: string): string {
        return this._lgTranslateService.translate("APP._Shared.Regions." + region.toUpperCase());
    }

    private _getRegionsSelection(): Regions | undefined {
        let regions: Regions | undefined;
        if (this._shouldSelectOnlyOneRegion && this._singleRegionSelection) {
            regions = [this._singleRegionSelection];
        } else {
            const [firstSelectedRegion, ...otherRegions] = Object.keys(this._regionSelection);
            if (firstSelectedRegion) {
                regions = [firstSelectedRegion, ...otherRegions];
            }
        }
        return regions;
    }

    private _getConnectSelection(regions?: Regions): IConnectBenchmark | undefined {
        if (!regions) return undefined;
        const connectProviders = Object.values(this._connectSelection).map(
            connectProvider => connectProvider.id,
        );
        return {
            type: "connect",
            connectProviders,
            regions,
        };
    }
}
