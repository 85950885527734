import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    inject,
} from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Overlay } from "@angular/cdk/overlay";

import { LgConsole } from "@logex/framework/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgOverlayService } from "@logex/framework/ui-core";

import { OverlayBase } from "@codman/shared/ui-overlay";

import {
    AllowedBenchmarks,
    IBenchmarkInitProps,
    IBenchmarkSelection,
    UiBenchmarkSelectorPopupComponent,
} from "../ui-benchmark-selector-popup/ui-benchmark-selector-popup.component";
import {
    Benchmark,
    BenchmarkType,
    IBenchmarkConfiguration,
    IConnectProvider,
    IPatientsCount,
    Regions,
} from "./../feature-benchmarking.types";
import { FeatureBenchmarkingService } from "../feature-benchmarking.service";

@Component({
    selector: "codman-feature-benchmark-selector",
    templateUrl: "./feature-benchmark-selector.component.html",
    styleUrls: ["./feature-benchmark-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [useTranslationNamespace("APP._Shared.BenchmarkSelector")],
})
export class FeatureBenchmarkSelectorComponent
    extends OverlayBase<IBenchmarkInitProps, Benchmark>
    implements OnChanges, OnDestroy
{
    private _lgConsole = inject(LgConsole);
    private _benchmarkingService = inject(FeatureBenchmarkingService);

    /**
     * Always shows national benchmark unless strictly specified as false
     * Other benchmarks need to be specified as true to show
     */
    @Input() allowedBenchmarks: AllowedBenchmarks = {};
    @Input() connectProviders: IConnectProvider[] = [];
    @Input() selectedRegions?: string[] | null;
    @Input() selectedBenchmark: BenchmarkType = "regional";
    @Input() shouldSelectOnlyOneRegion = false;
    @Input() benchmarkConfiguration: IBenchmarkConfiguration | null = null;
    @Input() currentRegistry: string | null = null;
    @Input() usePieChart = false;
    @Input() patientsCount: IPatientsCount | null = null;

    @Output() readonly selection = new EventEmitter<IBenchmarkSelection>();

    _connectSelection?: number[];
    _regionSelection?: Regions;
    _providerTypeSelection: string | undefined = undefined;
    _selectorDisabled = true;
    _selectedBenchmarkLabelPath$ = this._benchmarkingService.selectedBenchmarkLabel$;
    _selectedBenchmarkColor$ = this._benchmarkingService.selectedBenchmarkColor$;

    _numerator = 0;
    _denominator = 0;

    constructor(
        _elementRef: ElementRef,
        _scrollDispatcher: ScrollDispatcher,
        _overlayService: LgOverlayService,
        _overlay: Overlay,
    ) {
        super(
            _elementRef,
            _scrollDispatcher,
            _overlayService,
            _overlay,
            UiBenchmarkSelectorPopupComponent,
        );
    }

    ngOnChanges(changes: LgSimpleChanges<FeatureBenchmarkSelectorComponent>): void {
        if (changes.allowedBenchmarks) {
            this._changeAllowedBenchmarks();
        }
        if (changes.connectProviders && this.connectProviders) {
            this._checkProvidersSelection(this.connectProviders);
        }
        if (
            (changes.selectedRegions || changes.benchmarkConfiguration) &&
            this.selectedRegions?.length
        ) {
            this._setRegionSelection();
        }
        if (
            changes.benchmarkConfiguration &&
            this.benchmarkConfiguration &&
            this.benchmarkConfiguration.defaultProviderType
        ) {
            this._providerTypeSelection = this.benchmarkConfiguration.defaultProviderType;
        }
        if (changes.currentRegistry) {
            this._benchmarkingService.resetSelectedBenchmark();
            this._connectSelection = undefined;
        }
        if (changes.selectedBenchmark || changes.patientsCount) {
            switch (this.selectedBenchmark) {
                case "connect":
                    this._numerator = this.patientsCount?.connectBenchmarkNumerator || 0;
                    this._denominator = this.patientsCount?.connectBenchmarkDenominator || 0;
                    break;
                case "providerType":
                    this._numerator = this.patientsCount?.providerTypeBenchmarkNumerator || 0;
                    this._denominator = this.patientsCount?.providerTypeBenchmarkDenominator || 0;
                    break;
                default:
                case "regional":
                case "national":
                    this._numerator = this.patientsCount?.nationalBenchmarkNumerator || 0;
                    this._denominator = this.patientsCount?.nationalBenchmarkDenominator || 0;
                    break;
            }
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showBenchmarkSelector($event?: Event): void {
        $event?.stopPropagation();

        if (this._popupActive) return;

        if (!this.allowedBenchmarks[this.selectedBenchmark]) {
            const defaultBenchmark = Object.entries(this.allowedBenchmarks).find(
                ([_, isAllowed]) => isAllowed,
            );
            if (defaultBenchmark) {
                this.selectedBenchmark = <BenchmarkType>defaultBenchmark[0];
            } else {
                this._lgConsole.error(
                    "No available benchmarks configured, cannot render benchmark selector",
                );
                return;
            }
        }

        const result$ = this._showPopup(
            {
                allowedBenchmarks: this.allowedBenchmarks,
                selectedBenchmark: this.selectedBenchmark,
                connectProviders: this.connectProviders,
                connectSelection: this._connectSelection,
                regionSelection: this._regionSelection,
                providerTypeSelection: this._providerTypeSelection,
                bechmarkConfiguration: this.benchmarkConfiguration || {
                    regions: ["nl"],
                    defaultRegion: "",
                    providerTypes: [],
                    defaultProviderType: "",
                    connect: [],
                },
                shouldSelectOnlyOneRegion: this.shouldSelectOnlyOneRegion,
            },
            {
                positions: ["bottom-left", "bottom-center"],
            },
        );

        result$.subscribe({
            next: result => {
                switch (result.type) {
                    case "connect":
                        this._connectSelection = result.connectProviders;
                        break;
                    case "regional":
                        this._regionSelection = result.regions;
                        break;
                    case "providerType":
                        this._providerTypeSelection = result.providerType;
                        break;
                }

                this.selection.emit({
                    selectedBenchmark: result.type,
                    connectSelection: this._connectSelection,
                    regionSelection: this._regionSelection,
                    providerTypeSelection: this._providerTypeSelection,
                });
                this._benchmarkingService.updateSelectedBenchmark(result);
            },
            complete: () => this._hidePopup(),
        });

        this._popupActive = true;
    }

    private _changeAllowedBenchmarks(): void {
        this.allowedBenchmarks.national = this.allowedBenchmarks.national !== false;
        this._selectorDisabled =
            Object.values(this.allowedBenchmarks).filter(isAllowed => isAllowed).length < 1;
    }

    private _checkProvidersSelection(connectProviders: IConnectProvider[]): void {
        const selectedProvidersExist = connectProviders.every(selection =>
            connectProviders.find(provider => provider.id === selection.id),
        );
        if (!selectedProvidersExist) {
            this._connectSelection = undefined;
        }
    }

    private _setRegionSelection(): void {
        if (!this.selectedRegions || !this.benchmarkConfiguration) {
            this._regionSelection = undefined;
            return;
        }
        const [firstRegion, ...otherRegions] = this.selectedRegions;
        if (firstRegion) {
            this._regionSelection = [firstRegion, ...otherRegions];
        }
    }
}
