import { IFilterDefinition } from "@logex/framework/lg-filterset";

import { Range } from "@codman/shared/util-filters";
export interface IYearFilterDefinition extends IFilterDefinition {
    filterType: "year";
    min: number;
    max: number;
    default?: Range;
    registry: string;
    subset: string;
}

export type YearFilterStorage = Record<string, Range>;
