import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from "@angular/core";
import { FormsModule } from "@angular/forms";

import { LgSimpleChanges } from "@logex/framework/types";
import { LgTranslatePipe } from "@logex/framework/lg-localization";

export interface ISingleSelectorItem {
    id: string | number;
    name: string;
}

@Component({
    selector: "codman-ui-single-item-selector",
    templateUrl: "./ui-single-item-selector.component.html",
    styleUrls: ["./ui-single-item-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, LgTranslatePipe],
})
export class SharedUiSingleItemSelectorComponent implements OnChanges {
    @Input() items: ISingleSelectorItem[] = [];
    @Input() selectedItemId?: string | number;
    @Input() title = "APP._Shared.SelectOne";

    @Output() readonly itemSelection = new EventEmitter<string | number>();

    ngOnChanges(changes: LgSimpleChanges<SharedUiSingleItemSelectorComponent>): void {
        if (changes.items && this.items.length && this.selectedItemId == null) {
            this.selectedItemId = this.items[0].id;
            this.itemSelection.emit(this.selectedItemId);
        }
    }

    selectItem(): void {
        this.itemSelection.emit(this.selectedItemId);
    }
}
