import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";

import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { LgPanelGridDefinition } from "@logex/framework/lg-layout";

import { Range } from "@codman/shared/util-filters";
import { IOverlayPopup } from "@codman/shared/ui-overlay";

export interface HistogramPickerInitProps {
    min: number;
    max: number;
    selectedMin: number | null;
    selectedMax: number | null;
    source: Observable<number[]>;
}

export type HistogramPickerSelection = Range | null;

@Component({
    selector: "codman-histogram-picker-popup",
    templateUrl: "./histogram-picker-popup.component.html",
    styleUrls: ["./histogram-picker-popup.component.scss"],
})
export class HistogramPickerPopupComponent
    implements IOverlayPopup<HistogramPickerInitProps, HistogramPickerSelection>, OnInit, OnDestroy
{
    @Input() min = 0;
    @Input() max = 0;
    @Input() selectedMin: number | null = null;
    @Input() selectedMax: number | null = null;
    @Input() source!: Observable<number[]>;

    @Output() readonly userSelection = new EventEmitter<HistogramPickerSelection>();

    @ViewChild("histogramWrapper") histogramWrapper!: ElementRef;
    @ViewChild("histogramWrapperContent") histogramWrapperContent!: ElementRef;

    _gridDefinition: LgPanelGridDefinition = {
        columns: [{ size: 1, id: "top-left" }],
    };

    _loading = false;
    _error = false;

    _histogramBarHeightsPercentage: number[] = [];
    _histogramBarMarginPx = 0;
    _histogramBarWidthPx = 0;
    _histogramLeftOverlayWidthPx = 0;
    _histogramRightOverlayWidthPx = 0;

    _axisTickPosPx: number[] = [];
    _axisTickValues: number[] = [];
    _draggingActive = false;

    private readonly _destroyed$ = new Subject<void>();
    private _step = 0;
    private _data: number[] = [];

    private _minDraggingActive = false;
    private _maxDraggingActive = false;

    ngOnInit(): void {
        this.selectedMin = this.selectedMin ?? this.min;
        this.selectedMax = this.selectedMax ?? this.max;

        this._load();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    initialize(input: HistogramPickerInitProps): Observable<HistogramPickerSelection> {
        this.min = input.min;
        this.max = input.max;
        this.selectedMin = input.selectedMin;
        this.selectedMax = input.selectedMax;
        this.source = input.source;

        return this.userSelection;
    }

    _updatePosition(position: ConnectedOverlayPositionChange): void {
        //
    }

    _selectedMinChangedByInput(e: FocusEvent): void {
        (e.target as HTMLInputElement).value = String(
            this._selectMin(Number((e.target as HTMLInputElement).value.trim() || NaN)),
        );
    }

    _selectedMaxChangedByInput(e: FocusEvent): void {
        (e.target as HTMLInputElement).value = String(
            this._selectMax(Number((e.target as HTMLInputElement).value.trim() || NaN)),
        );
    }

    _doneClick(e: MouseEvent): void {
        this.userSelection.emit({
            min: this.selectedMin,
            max: this.selectedMax,
        });
    }

    _cancelClick(e: MouseEvent): void {
        this.userSelection.emit(null);
    }

    _activateMinDragging(e: MouseEvent): void {
        this._minDraggingActive = true;
        this._activateDragging();
    }

    _activateMaxDragging(e: MouseEvent): void {
        this._maxDraggingActive = true;
        this._activateDragging();
    }

    private _recalc(): void {
        const dataMax = Math.max(...this._data);
        this._histogramBarHeightsPercentage = this._data.map(value =>
            Math.round((value / dataMax) * 100),
        );

        const valueOffset = Math.floor((this.max - this.min) / 7);
        this._axisTickValues = Array.from({ length: 6 }).map(
            (value, index) => (index + 1) * valueOffset + this.min,
        );

        const width = this.histogramWrapperContent.nativeElement.offsetWidth;
        const totalCount = this._data.length;

        const barSpacePx = width / totalCount;
        const barWidth = Math.floor(barSpacePx - 1);
        const marginPx = barSpacePx - barWidth;

        this._histogramBarWidthPx = barWidth;
        this._histogramBarMarginPx = marginPx;

        this._step = this._histogramBarWidthPx + this._histogramBarMarginPx;
        this._axisTickPosPx = this._axisTickValues.map(
            (value, index) => (value - this.min) * this._step,
        );

        this._selectMin(this.selectedMin ?? this.min);
        this._selectMax(this.selectedMax ?? this.max);
    }

    private _load(): void {
        this._loading = true;
        this._loadFromSource().subscribe({
            next: data => {
                this._loading = false;
                this._data = data;
                requestAnimationFrame(() => this._recalc());
            },
            error: () => {
                this._loading = false;
                this._error = true;
            },
        });
    }

    private _loadFromSource(): Observable<number[]> {
        return this.source.pipe(take(1), takeUntil(this._destroyed$));
    }

    private _selectMin(newValue: number): number {
        if (isNaN(newValue)) newValue = Number(this.selectedMin);
        const selectedMax = this.selectedMax == null ? 0 : this.selectedMax;

        this.selectedMin = Math.max(newValue, this.min);
        this.selectedMin = Math.min(this.selectedMin, selectedMax, this.max);
        this._histogramLeftOverlayWidthPx = (this.selectedMin - this.min) * this._step;

        return this.selectedMin;
    }

    private _selectMax(newValue: number): number {
        if (isNaN(newValue)) newValue = Number(this.selectedMax);
        const selectedMin = this.selectedMin == null ? 0 : this.selectedMin;

        this.selectedMax = Math.min(newValue, this.max);
        this.selectedMax = Math.max(this.selectedMax, selectedMin, this.min);
        this._histogramRightOverlayWidthPx = Math.floor(
            (this.max - this.selectedMax) * this._step + this._histogramBarMarginPx,
        );

        return this.selectedMax;
    }

    private _activateDragging(): void {
        this._draggingActive = true;

        document.addEventListener("mouseup", this._onDragMouseUp);
        document.addEventListener("mousemove", this._onDragMouseMove);
    }

    private _onDragMouseMove = (e: MouseEvent): void => {
        const { clientX } = e;
        const { left, width } = this.histogramWrapper.nativeElement.getBoundingClientRect();

        const diff = clientX - left;

        if (diff < 0) {
            if (this._minDraggingActive) this._selectMin(this.min);
            if (this._maxDraggingActive) this._selectMax(this.min);
            return;
        }

        if (diff > width) {
            if (this._minDraggingActive) this._selectMin(this.max);
            if (this._maxDraggingActive) this._selectMax(this.max);
            return;
        }

        if (Math.abs(diff) < this._step) {
            return;
        }

        const newValue = Math.ceil(diff / this._step);

        if (this._minDraggingActive) this._selectMin(this.min + newValue);
        if (this._maxDraggingActive) this._selectMax(this.min + newValue);
    };

    private _onDragMouseUp = (e: MouseEvent): void => {
        this._draggingActive = false;
        this._minDraggingActive = false;
        this._maxDraggingActive = false;

        document.removeEventListener("mouseup", this._onDragMouseUp);
        document.removeEventListener("mousemove", this._onDragMouseMove);
    };
}
