import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";

import { INavNode } from "@logex/framework/lg-application";

import { CodmanStructure } from "./data-access-shared-api.types";
import { fakeCodmanStructure } from "./fake-api";

export class SharedNavigationMockService {
    readonly structureBasedOnPermissions$: Observable<CodmanStructure> = of(
        fakeCodmanStructure,
    ).pipe(delay(50));

    readonly navigation$: Observable<INavNode[]> = of([
        {
            id: "root",
            path: "",
            noBreadcrumb: true,
            children: [
                {
                    id: "Dcra",
                    lid: "Registries.Dcra.Abbreviation",
                    path: "Dcra",
                    children: [
                        {
                            lid: "APP._Shared.Applications.Descriptives.Name",
                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra",
                            data: { appName: "descriptives" },
                            children: [
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2021",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2021",
                                    children: [
                                        {
                                            lid: "Klinisch",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2021/subset/1",
                                        },
                                        {
                                            lid: "Colon",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2021/subset/2",
                                        },
                                        {
                                            lid: "Rectum",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2021/subset/3",
                                        },
                                        {
                                            lid: "Proms",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2021/subset/4",
                                        },
                                    ],
                                },
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2022",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2022",
                                    children: [
                                        {
                                            lid: "Klinisch",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2022/subset/1",
                                        },
                                        {
                                            lid: "Colon",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2022/subset/2",
                                        },
                                        {
                                            lid: "Rectum",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dcra/default-2022/subset/3",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Indicators.Name",
                            path: "https://codman-indicators.dica.test.nl.mrdm.com/Dcra",
                            data: { appName: "indicators" },
                            children: [
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2020",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dcra/definitions2020",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2021",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dcra/definitions2021",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2022",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dcra/definitions2022",
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Exploration.Name",
                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra",
                            data: { appName: "exploration" },
                            children: [
                                {
                                    lid: "Registries.Dcra.Subsets.primaryColonCancer.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryColonCancer",
                                    children: [
                                        {
                                            lid: "Registries.Dcra.Subsets.primaryColonCancer.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryColonCancer/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.Dcra.Subsets.primaryColonCancer.Pages.promsOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryColonCancer/promsOutcomes",
                                        },
                                    ],
                                },
                                {
                                    lid: "Registries.Dcra.Subsets.primaryRectalCancer.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryRectalCancer",
                                    children: [
                                        {
                                            lid: "Registries.Dcra.Subsets.primaryRectalCancer.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryRectalCancer/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.Dcra.Subsets.primaryRectalCancer.Pages.promsOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dcra/subsets/primaryRectalCancer/promsOutcomes",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Patients.Name",
                            path: "https://Dcra-patients-test.valuebase.nl/dashboard",
                            data: { appName: "patients" },
                            children: [
                                {
                                    lid: "APP._Navigation.Patients.Subsets.rectal-cancer",
                                    path: "https://Dcra-patients-test.valuebase.nl/dashboard/rectal-cancer",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "DlcaS",
                    lid: "Registries.DlcaS.Abbreviation",
                    path: "DlcaS",
                    children: [
                        {
                            lid: "APP._Shared.Applications.Descriptives.Name",
                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS",
                            data: { appName: "descriptives" },
                            children: [
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2021",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021",
                                    children: [
                                        {
                                            lid: "Klinisch",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021/subset/1",
                                        },
                                        {
                                            lid: "Verdenking longcarcinoom",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021/subset/2",
                                        },
                                        {
                                            lid: "Mediastinale tumoren",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021/subset/3",
                                        },
                                        {
                                            lid: "Metastasectomie",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021/subset/4",
                                        },
                                        {
                                            lid: "Overige ingrepen (geen reïnterventies)",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2021/subset/5",
                                        },
                                    ],
                                },
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2022",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022",
                                    children: [
                                        {
                                            lid: "Klinisch",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022/subset/1",
                                        },
                                        {
                                            lid: "Verdenking longcarcinoom",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022/subset/2",
                                        },
                                        {
                                            lid: "Mediastinale tumoren",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022/subset/3",
                                        },
                                        {
                                            lid: "Metastasectomie",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022/subset/4",
                                        },
                                        {
                                            lid: "Overige ingrepen (geen reïnterventies)",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/DlcaS/default-2022/subset/5",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Indicators.Name",
                            path: "https://codman-indicators.dica.test.nl.mrdm.com/DlcaS",
                            data: { appName: "indicators" },
                            children: [
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2020",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/DlcaS/definitions2020",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2021",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/DlcaS/definitions2021",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2022",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/DlcaS/definitions2022",
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Exploration.Name",
                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS",
                            data: { appName: "exploration" },
                            children: [
                                {
                                    lid: "Registries.DlcaS.Subsets.resectionOfLungCarcinoma.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/resectionOfLungCarcinoma",
                                    children: [
                                        {
                                            lid: "Registries.DlcaS.Subsets.resectionOfLungCarcinoma.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/resectionOfLungCarcinoma/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.DlcaS.Subsets.resectionOfLungCarcinoma.Pages.survivalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/resectionOfLungCarcinoma/survivalOutcomes",
                                        },
                                    ],
                                },
                                {
                                    lid: "Registries.DlcaS.Subsets.mediastiumSurgery.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/mediastiumSurgery",
                                    children: [
                                        {
                                            lid: "Registries.DlcaS.Subsets.mediastiumSurgery.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/mediastiumSurgery/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.DlcaS.Subsets.mediastiumSurgery.Pages.survivalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/mediastiumSurgery/survivalOutcomes",
                                        },
                                    ],
                                },
                                {
                                    lid: "Registries.DlcaS.Subsets.metastasectomy.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/metastasectomy",
                                    children: [
                                        {
                                            lid: "Registries.DlcaS.Subsets.metastasectomy.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/metastasectomy/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.DlcaS.Subsets.metastasectomy.Pages.survivalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/DlcaS/subsets/metastasectomy/survivalOutcomes",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "Dato",
                    lid: "Registries.Dato.Abbreviation",
                    path: "Dato",
                    children: [
                        {
                            lid: "APP._Shared.Applications.Descriptives.Name",
                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato",
                            data: { appName: "descriptives" },
                            children: [
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2021",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2021",
                                    children: [
                                        {
                                            lid: "Primaire ingrepen",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2021/subset/1",
                                        },
                                        {
                                            lid: "Secundaire ingrepen en revisie",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2021/subset/2",
                                        },
                                        {
                                            lid: "Proms",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2021/subset/3",
                                        },
                                    ],
                                },
                                {
                                    lid: "APP._Navigation.Descriptives.ReferenceProfile.default-2022",
                                    path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2022",
                                    children: [
                                        {
                                            lid: "Primaire ingrepen",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2022/subset/1",
                                        },
                                        {
                                            lid: "Secundaire ingrepen en revisie",
                                            path: "https://codman-descriptives.dica.test.nl.mrdm.com/Dato/default-2022/subset/2",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Indicators.Name",
                            path: "https://codman-indicators.dica.test.nl.mrdm.com/Dato",
                            data: { appName: "indicators" },
                            children: [
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2020",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dato/definitions2020",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2021",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dato/definitions2021",
                                },
                                {
                                    lid: "APP._Navigation.Indicators.Pages.definitions2022",
                                    path: "https://codman-indicators.dica.test.nl.mrdm.com/Dato/definitions2022",
                                },
                            ],
                        },
                        {
                            lid: "APP._Shared.Applications.Exploration.Name",
                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dato",
                            data: { appName: "exploration" },
                            children: [
                                {
                                    lid: "Registries.Dato.Subsets.bariatricSurgery.Label",
                                    path: "https://codman-exploration.dica.test.nl.mrdm.com/Dato/subsets/bariatricSurgery",
                                    children: [
                                        {
                                            lid: "Registries.Dato.Subsets.bariatricSurgery.Pages.clinicalOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dato/subsets/bariatricSurgery/clinicalOutcomes",
                                        },
                                        {
                                            lid: "Registries.Dato.Subsets.bariatricSurgery.Pages.promsOutcomes.Label",
                                            path: "https://codman-exploration.dica.test.nl.mrdm.com/Dato/subsets/bariatricSurgery/promsOutcomes",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "overview",
                    path: "https://codman-overview.dica.test.nl.mrdm.com/",
                    lid: "APP._Shared.Applications.Overview.Name",
                    inNewTab: true,
                    anonymous: false,
                },
                {
                    id: "portalExt",
                    path: "https://codman.dica.test.nl.mrdm.com/",
                    lid: "Codman",
                    inNewTab: true,
                    hidden: true,
                },
            ],
        },
    ]);

    getNavigation$(): Observable<INavNode[]> {
        return this.navigation$;
    }
}
