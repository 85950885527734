import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { LgIconComponent } from "@logex/framework/ui-core";

import { SharedLabelledInputComponent } from "@codman/shared/ui-labelled-input";
import { Range } from "@codman/shared/util-filters";

@Component({
    selector: "codman-range-filter-input",
    templateUrl: "./range-filter-input.component.html",
    styleUrls: ["./range-filter-input.component.scss"],
    standalone: true,
    imports: [CommonModule, LgIconComponent, SharedLabelledInputComponent],
})
export class SharedRangeFilterInputComponent {
    @Input() labelLc = "";
    @Input() range: Range = {
        min: null,
        max: null,
    };

    @Output() readonly inputClick = new EventEmitter();
    @Output() readonly inputClearClick = new EventEmitter();

    _emitInputClick(e: MouseEvent): void {
        this.inputClick.emit();
    }

    _emitInputClearClick(e: MouseEvent): void {
        e.stopPropagation();
        this.inputClearClick.emit();
    }
}
