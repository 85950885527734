import { inject, Injectable, LOCALE_ID } from "@angular/core";

import { BehaviorSubject, combineLatest, concat, Observable, of } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    shareReplay,
    startWith,
    switchMap,
    withLatestFrom,
} from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IComboFilter2WrappedId, IFilterList, LgFilterSet } from "@logex/framework/lg-filterset";

import {
    DefaultFilterFactoryCreator,
    FilterFactory,
    Range,
    TimeRange,
} from "@codman/shared/util-filters";
import {
    CodmanUserAuthorizationService,
    TENANT_LOOKUP,
} from "@codman/shared/data-access-authorization";

import { DataAccessStateService } from "./data-access-state.service";
import {
    Filters,
    MedicalItems,
    IFilterGroup,
    Filter,
    ICategoricalMedicalItem,
    YearsFilterRange,
    TimeRangeFilterRange,
    FilterCounts,
    FilterParams,
    CategoryQueryParams,
    RangeQueryParams,
    StringQueryParams,
    FilterParam,
    IRangeFilter,
    ActiveFilters,
} from "./data-access-api.types";
import { DataAccessApiService } from "./data-access-api.service";
import { FormStyle, getLocaleMonthNames, TranslationWidth } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import {
    allowedTrendIntervalUrlList,
    defaultTrendInterval,
    TrendInterval,
} from "@codman/shared/assets";
import {
    EMPTY_TIME_RANGE_VALUE,
    loadTimeRangeDefaultNumberOfYears,
    loadTimeRangeFromCache,
    loadTimeRangeRollingYear,
} from "@codman/shared/ui-time-range-filter";

export const TIME_RANGE_FILTER_NAME = "timeRange";
export const YEAR_FILTER_NAME = "years";
export const AGE_FILTER_NAME = "age";

@Injectable({
    providedIn: "root",
})
export class DataAccessFiltersService {
    private _filterFactory = inject(FilterFactory);
    private _lgTranslateService = inject(LgTranslateService);
    private _dataAccessApiService = inject(DataAccessApiService);
    private _dataAccessStateService = inject(DataAccessStateService);
    private _authorizationService = inject(CodmanUserAuthorizationService);
    private _locale = inject(LOCALE_ID);
    private _activatedRoute = inject(ActivatedRoute);

    readonly filterDefinition$: Observable<LgFilterSet>;
    readonly activeFilters$: Observable<ActiveFilters>;
    readonly trendInterval$: Observable<TrendInterval>;

    _monthNames = [
        ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated),
    ];

    constructor() {
        this.filterDefinition$ = combineLatest([
            this._dataAccessStateService.yearFilter$,
            this._dataAccessStateService.filters$,
            this._dataAccessStateService.medicalParameters$,
            this._dataAccessStateService.subsetConfiguration$,
        ]).pipe(
            debounceTime(50),
            withLatestFrom(
                this._dataAccessStateService.currentRegistry$,
                this._dataAccessStateService.currentSubset$,
            ),
            map(
                ([
                    [yearFilter, filters, medicalParameters, subsetConfiguration],
                    registryId,
                    subsetId,
                ]) =>
                    this.setupFilterDefinition(
                        yearFilter,
                        filters,
                        medicalParameters,
                        registryId,
                        subsetId,
                        subsetConfiguration.defaultNumberOfYearsSelected,
                    ),
            ),
            shareReplay(1),
        );

        this._dataAccessStateService.navigationState$.subscribe(() => this.clearFilters());

        this.trendInterval$ = this._activatedRoute.queryParams.pipe(
            map(queryParams => {
                const currentTrendInterval =
                    queryParams &&
                    queryParams["trendInterval"] &&
                    allowedTrendIntervalUrlList.includes(queryParams["trendInterval"])
                        ? queryParams["trendInterval"]
                        : null;
                const localStorageTrendInterval = localStorage.getItem(
                    "trendInterval",
                ) as TrendInterval;
                const currentLocalStorageTrendInterval =
                    localStorageTrendInterval &&
                    allowedTrendIntervalUrlList.includes(localStorageTrendInterval)
                        ? localStorageTrendInterval
                        : null;

                return (
                    currentTrendInterval
                        ? currentTrendInterval
                        : currentLocalStorageTrendInterval
                          ? currentLocalStorageTrendInterval
                          : defaultTrendInterval
                ) as TrendInterval;
            }),
        );

        this.activeFilters$ = this.filterDefinition$.pipe(
            switchMap(definition =>
                definition.onChanged.pipe(
                    map(() => this.getActiveFilters(definition)),
                    startWith({
                        timeRange: { minYear: null, maxYear: null, minMonth: null, maxMonth: null },
                    }),
                ),
            ),
            shareReplay(1),
            distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
            withLatestFrom(
                this._dataAccessStateService.currentRegistry$,
                this._dataAccessStateService.currentSubset$,
                this.trendInterval$,
                this._dataAccessStateService.subsetConfiguration$,
            ),
            map(
                ([
                    activeFilters,
                    currentRegistry,
                    currentSubset,
                    trendInterval,
                    subsetConfiguration,
                ]) => {
                    if (activeFilters.timeRange.minYear === null) {
                        const timeRangeRollingYear = loadTimeRangeRollingYear();
                        const timeRangeFromCache = loadTimeRangeFromCache(
                            currentRegistry,
                            currentSubset,
                        );
                        const timeRangeDefaultNumberOfYears = loadTimeRangeDefaultNumberOfYears(
                            subsetConfiguration.defaultNumberOfYearsSelected,
                        );
                        if (
                            trendInterval &&
                            trendInterval === "rollingYear" &&
                            timeRangeRollingYear
                        ) {
                            activeFilters.timeRange = timeRangeRollingYear;
                        } else if (timeRangeFromCache) {
                            activeFilters.timeRange = timeRangeFromCache;
                        } else if (
                            subsetConfiguration.defaultNumberOfYearsSelected &&
                            timeRangeDefaultNumberOfYears
                        ) {
                            activeFilters.timeRange = timeRangeDefaultNumberOfYears;
                        } else {
                            activeFilters.timeRange = EMPTY_TIME_RANGE_VALUE;
                        }
                    }
                    return activeFilters;
                },
            ),
        );
    }

    getActiveFilters(filterDefinition: LgFilterSet): ActiveFilters {
        const result: ActiveFilters = {
            timeRange: { minYear: null, maxYear: null, minMonth: null, maxMonth: null },
        };

        if (filterDefinition.filters == null) {
            console.warn("Attempting to get active filters when no filters are defined");
            return result;
        }

        const filters = { ...filterDefinition.filters };

        const timeRangeFilter: TimeRange | undefined = filters[TIME_RANGE_FILTER_NAME];
        if (timeRangeFilter) {
            result.timeRange = { ...timeRangeFilter };
            delete filters[TIME_RANGE_FILTER_NAME];
        }

        const activeFilters = this._getAndSortActiveFilters(filters, filterDefinition);
        if (activeFilters.length) {
            result.filters = activeFilters;
        }

        return result;
    }

    setupFilterDefinition(
        yearFilter: TimeRangeFilterRange,
        filters: Filters,
        medicalParameters: MedicalItems,
        registryId: string,
        subsetId: string,
        defaultNumberOfYearsSelected?: number,
    ): LgFilterSet {
        const filterFactory = this._filterFactory.define();

        this._addTimeRangeFilter(
            filterFactory,
            yearFilter,
            registryId,
            subsetId,
            defaultNumberOfYearsSelected,
        );
        this._addFilters(filterFactory, filters, medicalParameters, registryId);

        const filterDefinition = filterFactory.create(this);
        return filterDefinition;
    }

    clearFilters(): void {
        this.filterDefinition$.pipe(first()).subscribe(definition => definition.clearAll());
    }

    private _addFilters(
        filterFactory: DefaultFilterFactoryCreator,
        filters: Filters,
        medicalParameters: MedicalItems,
        registryId: string,
    ): void {
        if (
            filterFactory.addMappedNumberCombo2Filter == null &&
            filterFactory.addMappedStringCombo2Filter != null
        ) {
            return;
        }

        for (const filterGroup of filters) {
            this._addFilterGroup(filterFactory, filterGroup, medicalParameters, registryId);
        }
    }

    private _addFilterGroup(
        filterFactory: DefaultFilterFactoryCreator,
        filterGroup: IFilterGroup,
        medicalParameters: MedicalItems,
        registryId: string,
    ): void {
        filterFactory.startGroup(`Registries.${registryId}.FilterGroups.${filterGroup.groupId}`);
        filterGroup.filters.forEach(filter => {
            this._addFilter(filterFactory, filter, medicalParameters, registryId);
        });
    }

    private _addFilter(
        filterFactory: DefaultFilterFactoryCreator,
        filter: Filter,
        medicalParameters: MedicalItems,
        registryId: string,
    ): void {
        const filterId = filter.outcomeCode;
        switch (filter.filterType) {
            case "Category":
                this._addCategoryFilter(
                    filterFactory,
                    filterId,
                    <ICategoricalMedicalItem>medicalParameters[filterId],
                    registryId,
                );
                break;
            case "Range":
                this._addRangeFilter(filterFactory, filterId, filter, registryId);
                break;
            case "String":
                this._addStringSearchFilter(filterFactory, filterId, registryId);
                break;
            case "HistogramRange":
                this._addHistogramAgeFilter(filterFactory, filterId, {
                    minValue: filter.minValue,
                    maxValue: filter.maxValue,
                });
                break;

            // TODO: add other filter types
            default:
                break;
        }
    }

    private _addYearFilter(
        filterFactory: DefaultFilterFactoryCreator,
        { min, max }: YearsFilterRange,
        currentRegistry: string,
        currentSubset: string,
    ): void {
        localStorage.setItem("yearLimits", JSON.stringify({ min, max }));

        if (min == null || max == null) return;

        filterFactory.addFilter(YEAR_FILTER_NAME, {
            filterType: "year",
            min,
            max,
            nameLC: "APP._Shared.Filters.Years.Label",
            registry: currentRegistry,
            subset: currentSubset,
        });
    }

    private _addTimeRangeFilter(
        filterFactory: DefaultFilterFactoryCreator,
        { minYear, maxYear, minMonth, maxMonth }: TimeRangeFilterRange,
        currentRegistry: string,
        currentSubset: string,
        defaultNumberOfYearsSelected?: number,
    ): void {
        localStorage.setItem(
            "timeRangeLimits",
            JSON.stringify({ minYear, maxYear, minMonth, maxMonth }),
        );

        if (minYear == null || maxYear == null || minMonth == null || maxMonth == null) return;

        filterFactory.addFilter(TIME_RANGE_FILTER_NAME, {
            filterType: "timeRange",
            minYear,
            maxYear,
            minMonth,
            maxMonth,
            nameLC: "APP._Shared.Filters.TimeRange.Label",
            registry: currentRegistry,
            subset: currentSubset,
            defaultNumberOfYearsSelected,
        });
    }

    private _addHistogramAgeFilter(
        filterFactory: DefaultFilterFactoryCreator,
        filterId: string,
        range: { minValue: number; maxValue: number },
    ): void {
        const { minValue: min, maxValue: max } = range;
        filterFactory.addFilter(AGE_FILTER_NAME, {
            filterType: "histogram",
            min,
            max,
            nameLC: "APP._Shared.Filters.Age.Label",
            source: (): Observable<number[]> =>
                this._getFilterCounts(filterId).pipe(
                    map(counts =>
                        Array.from({ length: max - min }, (value, index) => counts[index] ?? 0),
                    ),
                ),
        });
    }

    private _addCategoryFilter(
        filterFactory: DefaultFilterFactoryCreator,
        filterId: string,
        medicalParameter: ICategoricalMedicalItem,
        registryId: string,
    ): void {
        const label = this._lgTranslateService.translate(
            `Registries.${registryId}.MedicalParameters.${filterId}.Label`,
        );
        filterFactory.addMappedNumberCombo2Filter(filterId, {
            label,
            name: label,
            wide: true,
            showSelected: 5,
            maxRows: 3,
            showDataCounts: true,
            tooltip: this._lgTranslateService.translate(
                `Registries.${registryId}.MedicalParameters.${filterId}.Info`,
            ),
            source: () =>
                concat(
                    of(
                        Object.keys(medicalParameter.options).map(
                            option =>
                                <IComboFilter2WrappedId<number>>{
                                    id: +option,
                                    data: null,
                                },
                        ),
                    ),
                    this._getFilterCounts(filterId).pipe(
                        map(counts => {
                            return Object.keys(medicalParameter.options).map(
                                option =>
                                    <IComboFilter2WrappedId<number>>{
                                        id: +option,
                                        data: counts[option] ?? 0,
                                    },
                            );
                        }),
                    ),
                ),
            optionName: (id: number) => {
                const optionId = medicalParameter.options[id];
                if (optionId) {
                    const labelPath = `Registries.${registryId}.MedicalParameters.${filterId}.Options.${optionId}`;
                    return this._lgTranslateService.translate(labelPath);
                } else {
                    return "";
                }
            },
            optionOrderById: true,
        });
    }

    private _addRangeFilter(
        filterFactory: DefaultFilterFactoryCreator,
        filterId: string,
        filter: IRangeFilter,
        registryId: string,
    ): void {
        filterFactory.addMappedRangeFilter(filterId, {
            label: this._lgTranslateService.translate(
                `Registries.${registryId}.MedicalParameters.${filterId}.Label`,
            ),
            min: filter.minValue,
            max: filter.maxValue,
            default: { enabled: false, from: filter.minValue, to: filter.maxValue },
            showFixedSelectedValues: true,
            tickerStep: Infinity,
            formatter: "int",
            formatterOptions: { forceSign: false, decimals: 0 },
            debounceTime: 100,
        });
    }

    private _addStringSearchFilter(
        filterFactory: DefaultFilterFactoryCreator,
        filterId: string,
        registryId: string,
    ): void {
        const label = this._lgTranslateService.translate(
            `Registries.${registryId}.MedicalParameters.${filterId}.Label`,
        );
        filterFactory.addMappedStringCombo2Filter(filterId, {
            label,
            name: label,
            wide: true,
            showSelected: 5,
            maxRows: 3,
            showDataCounts: true,
            tooltip: this._lgTranslateService.translate(
                `Registries.${registryId}.MedicalParameters.${filterId}.Info`,
            ),
            source: () =>
                this._getFilterCounts(filterId).pipe(
                    map(counts =>
                        Object.entries(counts).map(
                            ([option, count]) =>
                                <IComboFilter2WrappedId<string>>{
                                    id: option,
                                    data: count ?? 0,
                                },
                        ),
                    ),
                ),
            optionName: (id: string) => id,
            optionOrderById: true,
        });
    }

    private _getFilterCounts(filterId: string): Observable<FilterCounts> {
        return this._dataAccessStateService.navigationState$.pipe(
            withLatestFrom(this.activeFilters$, this._authorizationService.organizationId$),
            switchMap(([{ registryId, subsetId }, { timeRange, filters }, organizationId]) =>
                this._dataAccessApiService.getFilterCounts(
                    registryId,
                    subsetId,
                    filterId,
                    organizationId,
                    timeRange,
                    filters,
                ),
            ),
        );
    }

    private _getAndSortActiveFilters(
        filters: IFilterList,
        filterDefinition: LgFilterSet,
    ): FilterParams {
        const activeFilters = Object.entries(filters).filter(([filterName]) =>
            filterDefinition.isActive(filterName),
        );

        return activeFilters
            .map(([filterId, filter]) => {
                const type = filterDefinition.getFilterDefinition(filterId).filterType;
                return this._mapFilterToCategory(filterId, filter, type);
            })
            .filter((filter): filter is FilterParam => filter != null);
    }

    private _mapFilterToCategory(id: string, filter: any, type: string): FilterParam | null {
        if (filter == null) {
            return null;
        }

        switch (type) {
            case "combo2":
                return <CategoryQueryParams>{
                    id,
                    type: "category",
                    values: Object.keys(filter),
                };
            case "range":
                return <RangeQueryParams>{
                    id,
                    type: "range",
                    min: Math.round(filter.from),
                    max: Math.round(filter.to),
                };
            case "histogram":
                return <RangeQueryParams>{
                    id,
                    type: "range",
                    min: filter.min,
                    max: filter.max,
                };
            case "string":
                return <StringQueryParams>{
                    id,
                    type: "string",
                    value: filter,
                };
            default:
                return null;
        }
    }

    getGeneralExportImageDescription(
        registryId: string,
        medicalParameters: MedicalItems,
        filters: FilterParam[],
        timeRange?: TimeRange,
    ): string {
        const filtersList: string[] = filters.map((appliedFilter: FilterParam) =>
            this._getFilterLabel(appliedFilter, registryId, medicalParameters),
        );

        if (timeRange?.minYear) {
            filtersList.push(this._getTimeRangeFilterLabel(timeRange));
        }
        return (
            ". " +
            this._lgTranslateService.translate("APP.Exports.ImageSource", {
                registry: registryId.toUpperCase(),
                tenant: TENANT_LOOKUP[registryId].toUpperCase(),
            }) +
            (filtersList.length > 0
                ? " " +
                  this._lgTranslateService.translate("APP.Exports.ImageFilters", {
                      filters: filtersList.join("; "),
                  })
                : "")
        );
    }

    private _getFilterLabel(
        appliedFilter: FilterParam,
        registryId: string,
        medicalParameters: MedicalItems,
    ): string {
        const values: string[] = [];
        switch (appliedFilter.type) {
            case "category":
                appliedFilter.values?.forEach((value: string | number) => {
                    values.push(
                        this._lgTranslateService.translate(
                            `Registries.${registryId}.MedicalParameters.${
                                appliedFilter.id
                            }.Options.${
                                (medicalParameters[appliedFilter.id] as ICategoricalMedicalItem)
                                    ?.options[value]
                            }`,
                        ),
                    );
                });
                break;
            case "range":
                values.push(`(${appliedFilter.min}-${appliedFilter.max})`);
                break;
            case "timeRange":
                values.push(
                    appliedFilter.minYear === appliedFilter.maxYear
                        ? `: ${appliedFilter.minYear}`
                        : `: (${this._monthNames[appliedFilter.minMonth - 1]} ${
                              appliedFilter.minYear
                          } - ${this._monthNames[appliedFilter.maxMonth - 1]} ${
                              appliedFilter.maxYear
                          })`,
                );
                break;
            default:
            case "string":
            case "number":
                values.push(appliedFilter.value.toString());
                break;
        }

        return (
            this._lgTranslateService.translate(
                `Registries.${registryId}.MedicalParameters.${appliedFilter.id}.Label`,
            ) +
            ": " +
            values.join(", ")
        );
    }

    private _getYearFilterLabel(years: Range): string {
        return (
            this._lgTranslateService.translate(`APP._Shared.Filters.Years.Label`) +
            (years.min === years.max ? `: ${years.min}` : `: (${years.min} - ${years.max})`)
        );
    }

    private _getTimeRangeFilterLabel(timeRange: TimeRange): string {
        if (timeRange.minYear && timeRange.maxYear && timeRange.minMonth && timeRange.maxMonth) {
            return (
                `${this._lgTranslateService.translate(`APP._Shared.Filters.TimeRange.Label`)} ` +
                (timeRange.minYear === timeRange.maxYear
                    ? `: ${timeRange.minYear}`
                    : `: (${this._monthNames[timeRange.minMonth - 1]} ${timeRange.minYear} - ${
                          this._monthNames[timeRange.maxMonth - 1]
                      } ${timeRange.maxYear})`)
            );
        }

        return this._lgTranslateService.translate(`APP._Shared.Filters.TimeRange.Label`);
    }
}
