import { TimeRange } from "@codman/shared/util-filters";

export const EMPTY_TIME_RANGE_VALUE: TimeRange = {
    minYear: null,
    maxYear: null,
    minMonth: null,
    maxMonth: null,
};

export function loadTimeRangeFromCache(
    currentRegistry: string,
    currentSubset: string,
): TimeRange | undefined {
    const selectedTimeRange = localStorage.getItem("timeRange");
    const parsedTimeRange = JSON.parse(selectedTimeRange ?? "{}");

    const minYear = Number(parsedTimeRange?.minYear);
    const maxYear = Number(parsedTimeRange?.maxYear);
    const minMonth = Number(parsedTimeRange?.minMonth);
    const maxMonth = Number(parsedTimeRange?.maxMonth);
    const limits = loadTimeRangeLimitsFromCache();

    if (
        minYear &&
        maxYear &&
        minMonth &&
        maxMonth &&
        limits &&
        limits.minYear &&
        limits.maxYear &&
        limits.minMonth &&
        limits.maxMonth &&
        parsedTimeRange?.registry === currentRegistry &&
        parsedTimeRange?.subset !== currentSubset
    ) {
        return getLimitedValues(limits, {
            minYear,
            maxYear,
            minMonth,
            maxMonth,
        });
    }
    return undefined;
}

export function loadTimeRangeRollingYear(): TimeRange | undefined {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const limits = loadTimeRangeLimitsFromCache();
    if (limits && limits.minYear && limits.maxYear && limits.minMonth && limits.maxMonth)
        return getLimitedValues(limits, {
            minYear: currentYear - 1,
            maxYear: currentYear,
            minMonth: currentMonth + 2,
            maxMonth: currentMonth + 1,
        });

    return undefined;
}

export function loadTimeRangeDefaultNumberOfYears(
    defaultNumberOfYearsSelected?: number,
): TimeRange | undefined {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const limits = loadTimeRangeLimitsFromCache();
    if (defaultNumberOfYearsSelected === undefined) return undefined;
    if (limits && limits.minYear && limits.maxYear && limits.minMonth && limits.maxMonth)
        return getLimitedValues(limits, {
            minYear: currentYear - defaultNumberOfYearsSelected,
            maxYear: currentYear,
            minMonth: currentMonth + 2,
            maxMonth: currentMonth + 1,
        });
    return undefined;
}

function loadTimeRangeLimitsFromCache(): TimeRange | undefined {
    const yearsLimits = localStorage.getItem("timeRangeLimits");
    const parsedLimits = JSON.parse(yearsLimits ?? "{}");
    const parsedMinYear = Number(parsedLimits?.minYear);
    const parsedMaxYear = Number(parsedLimits?.maxYear);
    const parsedMinMonth = Number(parsedLimits?.minMonth);
    const parsedMaxMonth = Number(parsedLimits?.maxMonth);
    if (
        !isNaN(parsedMinYear) &&
        !isNaN(parsedMaxYear) &&
        !isNaN(parsedMinMonth) &&
        !isNaN(parsedMaxMonth)
    ) {
        return {
            minYear: parsedMinYear,
            maxYear: parsedMaxYear,
            minMonth: parsedMinMonth,
            maxMonth: parsedMaxMonth,
        };
    } else {
        return undefined;
    }
}

function getLimitedValues(limit: TimeRange, values: TimeRange): TimeRange | undefined {
    if (
        values.minYear === null ||
        values.maxYear === null ||
        values.minMonth === null ||
        values.maxMonth === null ||
        limit.minYear === null ||
        limit.maxYear === null ||
        limit.minMonth === null ||
        limit.maxMonth === null
    )
        return undefined;

    let minYear = values.minYear;
    let maxYear = values.maxYear;
    let minMonth = values.minMonth;
    let maxMonth = values.maxMonth;

    if (minYear > limit.maxYear || maxYear < limit.minYear) return EMPTY_TIME_RANGE_VALUE;
    if (minYear === limit.maxYear && minMonth > limit.maxMonth) return EMPTY_TIME_RANGE_VALUE;
    if (maxYear === limit.minYear && maxMonth < limit.minMonth) return EMPTY_TIME_RANGE_VALUE;

    if (minYear < limit.minYear) {
        minYear = limit.minYear;
        minMonth = limit.minMonth;
    } else if (minYear === limit.minYear && minMonth < limit.minMonth) {
        minMonth = limit.minMonth;
    }

    if (maxYear > limit.maxYear) {
        maxYear = limit.maxYear;
        maxMonth = limit.maxMonth;
    } else if (maxYear === limit.maxYear && maxMonth > limit.maxMonth) {
        maxMonth = limit.maxMonth;
    }

    return { minYear, maxYear, minMonth, maxMonth };
}
