/* eslint-disable */
import { Injectable } from "@angular/core";

import * as _ from "lodash";

import { LgTranslateService } from "@logex/framework/lg-localization";
import * as Filters from "@logex/framework/lg-filterset";
import { IItemClusterFilterDefinition } from "@logex/framework/ui-toolbox";
import { INormalizedFilterDefinition } from "@logex/framework/lg-filterset";

import {
    FilterFactoryCreatorBase,
    IFilterFactoryEntry,
    MapFilterDefinition,
    MapFilterStore,
    SupportedFilters,
} from "./filter-factory-base";

// ---------------------------------------------------------------------------------------------
//  Implementation of the FilterFactory service
// ---------------------------------------------------------------------------------------------

export type DefaultFilterFactoryCreator = FilterFactoryCreator<
    Record<string, INormalizedFilterDefinition>,
    Filters.IFilterList
>;

@Injectable({ providedIn: "root" })
export class FilterFactory {
    constructor(
        private _filterSetService: Filters.LgFilterSetService,
        private _lgTranslateService: LgTranslateService, // private _definitions: DefinitionsService
    ) {
        // empty
    }

    define(): FilterFactoryCreator<
        Record<string, INormalizedFilterDefinition>,
        Filters.IFilterList
    > {
        return new FilterFactoryCreator<
            Record<string, INormalizedFilterDefinition>,
            Filters.IFilterList
        >(
            this._filterSetService,
            this._lgTranslateService,
            // this._definitions
        );
    }
}

// ---------------------------------------------------------------------------------------------
type DateFilterParameters = Pick<
    Filters.IDateFilterDefinition,
    "min" | "max" | "default" | "nameLC" | "initialMaxValue" | "initialMinValue" | "visible"
>;

type Combo2Parameters<T> = Pick<
    Filters.IComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "label"
    | "name"
    | "placeholder"
    | "wide"
    | "showSelected"
    | "maxRows"
    | "showDataCounts"
    | "tooltip"
    | "tooltipLC"
>;

type SelectableCombo2Parameters<T> = Pick<
    Filters.ISelectableComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "nameLC"
    | "onSelectionChanged"
    | "onSelectionRemoved"
>;

type CheckboxParameters = Pick<
    Filters.ICheckboxFilterDefinition,
    | "uncheckedFilters"
    | "default"
    | "previewTextLC"
    | "previewPopup"
    | "nameLC"
    | "onChanged"
    | "visible"
>;

type InputRangeParameters = Pick<
    Filters.IInputRangeFilterDefinition,
    "min" | "max" | "format" | "decimals" | "default" | "onChanged" | "visible" | "nameLC"
>;

type RangeFilterParameters = Partial<
    Pick<
        Filters.IRangeFilterDefinition,
        | "label"
        | "checkboxVisible"
        | "min"
        | "minusInfinity"
        | "max"
        | "plusInfinity"
        | "tickerStep"
        | "highlightStep"
        | "keyboardStep"
        | "minusInfinityText"
        | "plusInfinityText"
        | "default"
        | "onChanged"
        | "debounceTime"
        | "visible"
        | "nameLC"
        | "showFixedSelectedValues"
        | "formatter"
        | "formatterOptions"
    >
>;

// type DecodeComboType<T extends "number" | "string"> = T extends "number" ? number : T extends "string" ? string : never;

type CareProductClusterFilterParameters = Pick<
    IItemClusterFilterDefinition<number>,
    "source" | "onBecameActive" | "onBecameActiveLimit" | "onChanged" | "visible" | "nameLC"
> & { getVisibleProducts?: null | (() => number[]) };

// ---------------------------------------------------------------------------------------------
//  Implementation of the creator.
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreator<
    Definitions extends Record<string, INormalizedFilterDefinition> = {},
    Filters extends Filters.IFilterList = {},
> extends FilterFactoryCreatorBase {
    constructor(
        _filterSetService: Filters.LgFilterSetService,
        _lgTranslateService: LgTranslateService,
        // private _definitions: DefinitionsService
    ) {
        super(_filterSetService, _lgTranslateService);
    }

    // ---------------------------------------------------------------------------------------------
    addFilter<T extends SupportedFilters, N extends string>(
        id: N,
        params: T,
    ): FilterFactoryCreator<
        Definitions & {
            [P in N]: MapFilterDefinition<T>;
        },
        Filters & {
            [P in N]: MapFilterStore<T>;
        }
    > {
        return this._addFilter(id, params);
    }

    // ---------------------------------------------------------------------------------------------
    create(context: any): Filters.LgFilterSet<Definitions, Filters> {
        return this._create<Definitions, Filters>(context);
    }

    // MY FILTERS
    // ---------------------------------------------------------------------------------------------
    agbCodeFilter<T extends string>(id: T, params: Combo2Parameters<string>) {
        return this.createCombo2String(id, params, {
            nameLC: "APP._Filters.AgbCode",
            mapToOptions: this.createMapper<string>("agbCode", true),
        });
    }

    private createMapper<T extends number | string>(id: string, sortById: boolean) {
        return (codes: T[]) =>
            _(codes)
                .map(code => {
                    // const name = this._definitions.getDisplayName(id, code)
                    return {
                        id: code,
                        order: sortById ? code : name,
                        name: code.toString(),
                    };
                })
                .sortBy(f => f.name)
                .value();
    }

    // ---------------------------------------------------------------------------------------------
    addMappedNumberCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<number> & {
            optionName: (id: number) => string;
            optionOrderById?: boolean;
        },
    ) {
        return this.createCombo2Number(id, params, {
            mapToOptions: ids =>
                _(ids)
                    .map(id => {
                        const name = params.optionName(id);
                        return {
                            id,
                            name,
                            sort: params.optionOrderById ? id : name,
                        };
                    })
                    .sortBy(f => f.sort)
                    .value(),
        });
    }

    // ---------------------------------------------------------------------------------------------
    addMappedStringCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<string> & {
            optionName: (id: string) => string;
            optionOrderById?: boolean;
        },
    ) {
        return this.createCombo2String(id, params, {
            mapToOptions: ids =>
                _(ids)
                    .map(id => {
                        const name = params.optionName(id);
                        return {
                            id,
                            name,
                            sort: params.optionOrderById ? id : name,
                        };
                    })
                    .sortBy(f => f.sort)
                    .value(),
        });
    }

    // ---------------------------------------------------------------------------------------------
    addMappedRangeFilter<T extends string>(id: T, params: RangeFilterParameters) {
        return this.createRange(id, params);
    }

    // ---------------------------------------------------------------------------------------------
    addCheckboxFilter<T extends string>(id: T, params: CheckboxParameters) {
        return this.createCheckbox(id, params, null);
    }

    // ---------------------------------------------------------------------------------------------
    addErrorFilter<T extends string>(id: T, params: CheckboxParameters) {
        return this.createCheckbox(id, params, {
            nameLC: "APP._Filters.Errors",
        });
    }

    // ---------------------------------------------------------------------------------------------
    //  Private helpers
    // ---------------------------------------------------------------------------------------------
    private createCombo2Number<T extends string>(
        id: T,
        params: Combo2Parameters<number>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>>,
    ) {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "number",
            visible: () => true,
            ...defaults,
            ...params,
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>);
    }

    // ---------------------------------------------------------------------------------------------
    private createCombo2String<T extends string>(
        id: T,
        params: Combo2Parameters<string>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>>,
    ) {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "string",
            visible: () => true,
            ...defaults,
            ...params,
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>);
    }

    private createInputRange<T extends string>(
        id: T,
        params: InputRangeParameters,
        defaults: Partial<InputRangeParameters>,
    ) {
        return this.addFilter(id, {
            filterType: "inputRange",
            main: true,
            visible: () => true,
            ...defaults,
            ...params,
        } as IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>);
    }

    private createRange<T extends string>(id: T, params: RangeFilterParameters) {
        return this.addFilter(id, {
            filterType: "range",
            ...params,
        } as IFilterFactoryEntry<Filters.IRangeFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    private createCheckbox<T extends string>(
        id: T,
        params: CheckboxParameters,
        defaults: Partial<IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>> | null,
    ) {
        return this.addFilter(id, {
            filterType: "checkbox",
            main: true,
            visible: () => true,
            ...defaults,
            ...params,
        } as IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
}
