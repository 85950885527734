import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { LG_FILTERSET_RENDERER_FACTORIES } from "@logex/framework/lg-filterset";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { SharedLabelledInputComponent } from "@codman/shared/ui-labelled-input";
import {
    HistogramFilterRendererFactory,
    HistogramFilterRendererLineComponent,
} from "./histogram-filter/histogram-filter-renderer";
import { HistogramFilterComponent } from "./histogram-filter/histogram-filter.component";
import { HistogramPickerPopupComponent } from "./histogram-filter/histogram-picker-popup/histogram-picker-popup.component";
import { SharedRangeFilterInputComponent } from "@codman/shared/ui-range-filter-input";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedLabelledInputComponent,
        UiCoreModule,
        LgLayoutModule,
        LgLocalizationModule,
        SharedRangeFilterInputComponent,
    ],
    providers: [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: HistogramFilterRendererFactory,
            multi: true,
        },
    ],
    declarations: [
        HistogramFilterComponent,
        HistogramPickerPopupComponent,
        HistogramFilterRendererLineComponent,
    ],
    exports: [HistogramFilterComponent, HistogramPickerPopupComponent],
})
export class SharedHistogramFilterModule {}
