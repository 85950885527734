import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    OrganizationIdentifier,
    AuthorizationPermission,
    AuthorizationProfile,
} from "./data-access-authorization-api.types";
import { FAKE_EXTENDED_PERMISSIONS, FAKE_ORGANIZATIONS, FAKE_PROFILE } from "./fake-api";

@Injectable({
    providedIn: "root",
})
export class DataAccessAuthorizationApiMockService {
    /**
     * User information
     */
    getOrganizations(
        params: { applicationInstance?: string } = {},
    ): Observable<AuthorizationOrganization[]> {
        return of(FAKE_ORGANIZATIONS);
    }

    getPermissions(
        params: {
            applicationInstance?: string;
            product?: string;
            organizationIdentifier?: string;
            organizationType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationPermission[]> {
        return of([]);
    }

    searchPermissions(
        params: {
            applicationInstances?: string[];
            products?: string[];
            organizations?: string[];
            organizationIdentifierType?: OrganizationIdentifier;
        } = {},
    ): Observable<AuthorizationExtendedPermission[]> {
        return of(
            FAKE_EXTENDED_PERMISSIONS.filter(
                perms => params.organizations?.includes(String(perms.cicCode)) ?? true,
            ),
        );
    }

    getProfile(): Observable<AuthorizationProfile> {
        return of(FAKE_PROFILE);
    }

    /**
     * Access checks
     */
    checkOrganization(params: {
        organizationIdentifier: string;
        organizationType: OrganizationIdentifier;
    }): Observable<boolean> {
        return of(true);
    }
}
