import { IFilterDefinition } from "@logex/framework/lg-filterset";

export interface Range {
    min: number | null;
    max: number | null;
}

export interface TimeRange {
    minYear: number | null;
    maxYear: number | null;
    minMonth: number | null;
    maxMonth: number | null;
}

export interface BaseRangeDefinition extends IFilterDefinition {
    min: number;
    max: number;
    default?: Range;
}

export type BaseRangeFilterStorage = Record<string, Range>;

export type BaseTimeRangeFilterStorage = Record<string, TimeRange>;
