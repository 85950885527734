import { IFilterDefinition } from "@logex/framework/lg-filterset";

import { TimeRange } from "@codman/shared/util-filters";

export interface ITimeRangeFilterDefinition extends IFilterDefinition {
    filterType: "timeRange";
    minYear: number;
    maxYear: number;
    minMonth: number;
    maxMonth: number;
    registry: string;
    subset: string;
    defaultNumberOfYearsSelected?: number;
}

export type TimeRangeFilterStorage = Record<string, TimeRange>;
