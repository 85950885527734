import { Observable, of } from "rxjs";
import { Dictionary } from "lodash";

import { IProduct } from "@logex/framework/lg-layout";
import { IDropdownDefinition } from "@logex/framework/ui-core";

import { CodmanApplication, RegistryTenant } from "@codman/shared/assets";
import {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    FAKE_EXTENDED_PERMISSIONS,
    FAKE_OVERVIEW_PERMISSIONS,
    FAKE_ORGANIZATIONS,
    FAKE_PROFILE,
    OrganizationIdentifier,
} from "@codman/shared/data-access-authorization-api";

import { AuthRegistryInfo, Tenants } from "./codman-user-authorization.types";
import { APP_INSTANCE_LOOKUP } from "@codman/shared/util-logex-framework-setup";

const MOCK_DATO_REGISTRY: AuthRegistryInfo = {
    product: "Product.Dato",
    tenant: "dica",
    id: "Dato",
    permissions: [
        {
            product: "Product.Dato",
            applicationInstance: "ApplicationInstance.CodmanDescriptives.NL",
            permissions: ["read"],
            name: "Logex Default",
            organizationId: -1,
            agbCode: null,
            cicCode: null,
            mrdmUri: null,
            position: "Unknown",
        },
        {
            product: "Product.Dato",
            applicationInstance: "ApplicationInstance.CodmanExploration.NL",
            permissions: ["read"],
            name: "Logex Default",
            organizationId: -1,
            agbCode: null,
            cicCode: null,
            mrdmUri: null,
            position: "Unknown",
        },
    ],
};

export class CodmanUserAuthorizationMockService {
    organizationIdentifierType: OrganizationIdentifier = "Global";

    readonly currentAppInstance = APP_INSTANCE_LOOKUP.exploration;

    readonly userProfile$ = of(FAKE_PROFILE);
    readonly allUserOrganizations$ = of(FAKE_ORGANIZATIONS);
    readonly userOrganizations$ = of(FAKE_ORGANIZATIONS);

    readonly organizationId$ = of(10003);
    readonly organizationLgId$ = of(1005245);
    readonly organizationName$ = of("Logex default");

    readonly availableRegistries$ = of(["Dato", "Duca", "Dcra", "AmsmReporting"]);
    readonly mrdmProducts$: Observable<IProduct[]> = of([]);

    allOrganizationsPermissions$ = of(FAKE_EXTENDED_PERMISSIONS);

    readonly permissionsByOrganization$: Observable<Dictionary<AuthorizationExtendedPermission[]>> =
        of({
            10003: FAKE_EXTENDED_PERMISSIONS,
        });

    readonly currentOrganizationPermissions$ = of(FAKE_EXTENDED_PERMISSIONS);

    readonly overviewRegistryPermissions$ = of(FAKE_OVERVIEW_PERMISSIONS);
    readonly canAccessOverview$ = of(true);

    getPermissionsForOrganization(
        organizationId: number,
    ): Observable<AuthorizationExtendedPermission[]> {
        return of(FAKE_EXTENDED_PERMISSIONS);
    }

    searchCurrentOrganizationPermissions(
        params: {
            applicationInstances?: string[];
            products?: string[];
        } = {},
    ): Observable<AuthorizationExtendedPermission[]> {
        return of(FAKE_EXTENDED_PERMISSIONS);
    }

    getListOfRegistryInfoFromAuth(
        organization?: string,
        app?: CodmanApplication,
    ): Observable<AuthRegistryInfo[]> {
        return of([MOCK_DATO_REGISTRY]);
    }

    getRegistriesGroupedByTenant(
        organization?: string,
        app?: CodmanApplication,
    ): Observable<Tenants> {
        return of(<Tenants>{
            dica: [MOCK_DATO_REGISTRY],
            idr: [],
            niv: [],
            swab: [],
            pice: [],
            rivm: [],
            dicawgz: [],
            none: [],
        });
    }

    getTenantRegistries(
        tenant: RegistryTenant,
        organization?: string,
        app?: CodmanApplication,
    ): Observable<AuthRegistryInfo[]> {
        return of([MOCK_DATO_REGISTRY]);
    }

    getRegistryInfo(
        registry: string,
        tenant: RegistryTenant,
        organization?: string,
    ): Observable<AuthRegistryInfo | undefined> {
        return of(MOCK_DATO_REGISTRY);
    }

    getOrganizationsByRegistryId(
        registry: string | null,
        app?: CodmanApplication,
    ): Observable<AuthorizationOrganization[]> {
        return of(FAKE_ORGANIZATIONS);
    }

    setDefaultOrganization(
        organizations: AuthorizationOrganization[],
        organizationIdFromQueryParams: string | null,
    ): void {
        //
    }

    getOrganizationsDropdownDefinition(
        registryId: string | null = null,
        { isNullWhenNoSelection } = { isNullWhenNoSelection: false },
    ): Observable<IDropdownDefinition<number> | null> {
        return of(null);
    }

    setOrganizationId(organizationId: number): void {
        //
    }
}
