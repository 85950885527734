import { Injectable, inject } from "@angular/core";

import { Observable } from "rxjs";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";

import { CodmanStructure, ORG_ID_HEADER } from "./data-access-shared-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessSharedApiService extends ServerGatewayBase {
    _configService = inject(SharedConfigService);

    constructor() {
        super();
        const apiUrl = this._configService.sharedApiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set application base API url based on configuration.");
        }
    }

    getStructure(organizationId: number): Observable<CodmanStructure> {
        return this._get<CodmanStructure>("/structure", {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }
}
