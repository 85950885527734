// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelled-input {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.labelled-input * {
  box-sizing: border-box;
}
.labelled-input__label {
  position: absolute;
  z-index: 1;
  top: -6px;
  left: 4px;
  height: 12px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 12px;
  background-color: white;
}
.labelled-input__content {
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
  height: 36px;
  line-height: 34px;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 var(--spacing1);
}
.labelled-input__content--selected {
  border-color: rgb(var(--gray-color-25));
}
.labelled-input__content-placeholder {
  color: rgb(var(--gray-color-60));
}
.labelled-input__content:hover {
  border-color: rgb(var(--gray-color-40));
}`, "",{"version":3,"sources":["webpack://./libs/shared/ui-labelled-input/src/lib/labelled-input/labelled-input.component.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;UAAA,iBAAA;EACA,kBAAA;AADJ;AAGI;EACI,sBAAA;AADR;AAII;EACI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;AAFR;AAKI;EACI,mCAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,0BAAA;AAHR;AAKQ;EACI,uCAAA;AAHZ;AAMQ;EACI,gCAAA;AAJZ;AAOQ;EACI,uCAAA;AALZ","sourcesContent":["@use \"definitions\" as *;\n\n.labelled-input {\n    user-select: none;\n    position: relative;\n\n    * {\n        box-sizing: border-box;\n    }\n\n    &__label {\n        position: absolute;\n        z-index: 1;\n        top: -6px;\n        left: 4px;\n        height: 12px;\n        padding: 0 6px;\n        font-size: 12px;\n        line-height: 12px;\n        background-color: white;\n    }\n\n    &__content {\n        border-radius: var(--border-radius);\n        border: 1px solid #ccc;\n        height: 36px;\n        line-height: 34px;\n        width: 100%;\n        position: relative;\n        overflow: hidden;\n        cursor: pointer;\n        padding: 0 var(--spacing1);\n\n        &--selected {\n            border-color: lg-color(--gray-color-25);\n        }\n\n        &-placeholder {\n            color: lg-color(--gray-color-60);\n        }\n\n        &:hover {\n            border-color: lg-color(--gray-color-40);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
