import { AuthorizationExtendedPermission } from "@codman/shared/data-access-authorization-api";

import { parseProductCode } from "./product-code";
import { AuthRegistryInfo } from "./codman-user-authorization.types";

export function getUniqRegistriesFromPermissions(
    permissions: AuthorizationExtendedPermission[],
): AuthRegistryInfo[] {
    const registries = permissions.reduce(
        (resultRegistries, permission) => {
            const existingRegistry = resultRegistries.find(
                registry => registry.product === permission.product,
            );
            if (existingRegistry == null) {
                const registry = parseProductCode(permission.product);
                if (registry != null) {
                    resultRegistries.push({
                        product: permission.product,
                        ...registry,
                        permissions: [permission],
                    });
                }
            } else {
                existingRegistry.permissions.push(permission);
            }
            return resultRegistries;
        },
        <AuthRegistryInfo[]>[],
    );

    return registries.sort((firstRegistry, secondRegistry) =>
        firstRegistry.id.localeCompare(secondRegistry.id),
    );
}
