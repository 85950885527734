import { RegistryTenant } from "@codman/shared/assets";
import { AuthorizationExtendedPermission } from "@codman/shared/data-access-authorization-api";

export interface AuthRegistryInfo {
    tenant: RegistryTenant;
    id: string;
    product: string;
    permissions: AuthorizationExtendedPermission[];
}

export type Tenants = Record<RegistryTenant, AuthRegistryInfo[]>;

export interface ITenant {
    id: RegistryTenant;
    registries: AuthRegistryInfo[];
}
