import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { UiCoreModule } from "@logex/framework/ui-core";
import { SharedUiSingleItemSelectorComponent } from "@codman/shared/ui-single-item-selector";
import { FeatureBenchmarkSelectorComponent } from "./feature-benchmark-selector/feature-benchmark-selector.component";
import { UiBenchmarkSelectorPopupComponent } from "./ui-benchmark-selector-popup/ui-benchmark-selector-popup.component";
import { UiChartButtonComponent } from "./ui-chart-button/ui-chart-button.component";
import { LgChartsModule } from "@logex/framework/lg-charts";

@NgModule({
    imports: [
        CommonModule,
        UiCoreModule,
        FormsModule,
        LgLocalizationModule,
        SharedUiSingleItemSelectorComponent,
        LgChartsModule,
    ],
    declarations: [
        FeatureBenchmarkSelectorComponent,
        UiBenchmarkSelectorPopupComponent,
        UiChartButtonComponent,
    ],
    exports: [FeatureBenchmarkSelectorComponent],
})
export class SharedFeatureBenchmarkingModule {}
