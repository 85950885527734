import { BehaviorSubject, Observable } from "rxjs";

import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { IFilterDefinition, LgFilterSet } from "@logex/framework/lg-filterset";

import { Range, BaseRangeDefinition, BaseRangeFilterStorage } from "./base-range-types";

export abstract class BaseRangeRenderer<Definition extends BaseRangeDefinition> {
    protected _min: number;
    protected _max: number;

    protected _storage: Range | null = null;

    private _previewName = new BehaviorSubject<string>("");
    private _previewName$ = this._previewName.asObservable();

    constructor(
        protected _definition: Definition,
        protected _filters: BaseRangeFilterStorage,
        protected _filterDefinitions: IFilterDefinition[],
        protected _filterSet: LgFilterSet,
    ) {
        this._min = this._definition.min || 0;
        this._max = this._definition.max || 0;
    }

    createStorage(): void {
        if (this._filters[this._definition.storage as string] == null) {
            this._createStorage();
        }
    }

    update(selectedMin: number | null, selectedMax: number | null): boolean {
        if (!this._storage) {
            return false;
        }

        const { min, max } = this._storage;

        this._storage.min = selectedMin != null ? Math.max(selectedMin, this._min) : null;
        this._storage.max = selectedMax != null ? Math.min(selectedMax, this._max) : null;

        if (this.previewVisible()) {
            const name = this._definition.nameLC
                ? this._filterSet.lgTranslate.translate(this._definition.nameLC)
                : this._definition.name;
            this._previewName.next(`${name} (${this.serialize()})`);
        }

        return min !== this._storage.min || max !== this._storage.max;
    }

    clear(): boolean {
        return this.update(null, null);
    }

    active(): boolean {
        return this._storage?.min != null || this._storage?.max != null;
    }

    previewVisible(): boolean {
        return this.active();
    }

    getPreviewName(): Observable<string> {
        return this._previewName$;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name || "",
            activeFn: () => this.active(),
            exportFn: () => [this._serializeValue()],
        };
    }

    serialize(): string {
        if (!this.active()) return "";
        return this._serializeValue();
    }

    deserialize(state: string): boolean {
        if (!this._storage) {
            return false;
        }

        const [selectedMin, selectedMax] = state.split("-");

        let numSelectedMin = null;

        if (selectedMin !== "" && isFinite(+selectedMin)) {
            numSelectedMin = Math.max(+selectedMin, this._min);
        }

        let numSelectedMax = null;

        if (selectedMax != null && isFinite(+selectedMax)) {
            numSelectedMax = Math.min(+selectedMax, this._max);
        }

        return this.update(numSelectedMin, numSelectedMax);
    }

    private _serializeValue(): string {
        if (!this._storage) {
            return "";
        }

        if (this._storage.min === this._storage.max && this._storage.min != null) {
            return String(this._storage.min);
        }

        const range = [];

        if (this._storage.min || this._storage.min === 0) {
            range.push(this._storage.min);
        }

        if (this._storage.max || this._storage.max === 0) {
            range.push(this._storage.max);
        }

        return range.join("-");
    }

    private _createStorage(): void {
        const key = this._definition.storage || "";

        this._filters[key] = {
            min: this._definition.default?.min ?? null,
            max: this._definition.default?.max ?? null,
        };

        this._storage = this._filters[key];
    }
}
