import { CommonModule, FormStyle, TranslationWidth, getLocaleMonthNames } from "@angular/common";
import {
    Component,
    EventEmitter,
    Input,
    LOCALE_ID,
    OnChanges,
    Output,
    inject,
} from "@angular/core";

import { LgIconComponent } from "@logex/framework/ui-core";

import { SharedLabelledInputComponent } from "@codman/shared/ui-labelled-input";

@Component({
    selector: "codman-time-range-filter-input",
    templateUrl: "./time-range-filter-input.component.html",
    styleUrls: ["./time-range-filter-input.component.scss"],
    standalone: true,
    imports: [CommonModule, LgIconComponent, SharedLabelledInputComponent],
})
export class SharedTimeRangeFilterInputComponent implements OnChanges {
    private _locale = inject(LOCALE_ID);

    @Input() labelLc = "";
    @Input() minYear: number | null = null;
    @Input() maxYear: number | null = null;
    @Input() minMonth: number | null = null;
    @Input() maxMonth: number | null = null;

    @Output() readonly inputClick = new EventEmitter();
    @Output() readonly inputClearClick = new EventEmitter();

    _monthNames = [
        ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated),
    ];

    _minYear: number | null = null;
    _maxYear: number | null = null;
    _minMonth: string | null = null;
    _maxMonth: string | null = null;

    ngOnChanges(): void {
        if (this.minYear && this.maxYear && this.minMonth && this.maxMonth) {
            this._minYear = this.minYear;
            this._maxYear = this.maxYear;
            this._minMonth = this._monthNames[this.minMonth - 1];
            this._maxMonth = this._monthNames[this.maxMonth - 1];
        } else {
            this._minYear = null;
            this._maxYear = null;
            this._minMonth = null;
            this._maxMonth = null;
        }
    }

    _emitInputClick(e: MouseEvent): void {
        this.inputClick.emit();
    }

    _emitInputClearClick(e: MouseEvent): void {
        e.stopPropagation();
        this.inputClearClick.emit();
    }
}
