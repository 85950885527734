import {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    AuthorizationPermission,
    AuthorizationProfile,
} from "./data-access-authorization-api.types";

export const FAKE_ORGANIZATIONS: AuthorizationOrganization[] = [
    {
        name: "Logex Default",
        organizationId: -1,
        agbCode: null,
        cicCode: null,
        mrdmUri: null,
        position: "Unknown",
        product: "Product.Dcra",
    },
    {
        name: "demo",
        organizationId: 1005245,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
        product: "Product.Dcra",
    },
    {
        name: "Academisch Medisch Centrum",
        position: "Unknown",
        organizationId: 1000080,
        agbCode: "06020702",
        cicCode: 103,
        mrdmUri: "amc",
        product: "Product.Dcra",
    },
];

export const FAKE_PERMISSIONS: AuthorizationPermission[] = [
    {
        product: "Product.Dato",
        permissions: ["read"],
    },
    {
        product: "Product.Duca",
        permissions: ["read"],
    },
    {
        product: "Product.Dcra",
        permissions: ["read"],
    },
    {
        product: "Product.AmsmReporting",
        permissions: ["read"],
    },
];

export const FAKE_EXTENDED_PERMISSIONS: AuthorizationExtendedPermission[] = [
    {
        product: "Platform.UserAdministration",
        applicationInstance: "ApplicationInstance.UserManagement.Global",
        permissions: ["ua_access"],
        name: "Academisch Medisch Centrum",
        position: "Unknown",
        organizationId: 1000080,
        agbCode: "06020702",
        cicCode: 103,
        mrdmUri: "amc",
    },
    {
        product: "Product.Dcra",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        permissions: ["read"],
        name: "Academisch Medisch Centrum",
        position: "Unknown",
        organizationId: 1000080,
        agbCode: "06020702",
        cicCode: 103,
        mrdmUri: "amc",
    },
    {
        product: "Product.Dcra",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        permissions: ["read"],
        name: "Demo",
        organizationId: 1005336,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
    },
    {
        product: "Product.Dcra",
        applicationInstance: "ApplicationInstance.CodmanIndicators.NL",
        permissions: ["read"],
        name: "Demo",
        organizationId: 1005336,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
    },
    {
        product: "Product.Dcra",
        applicationInstance: "ApplicationInstance.CodmanDescriptives.NL",
        permissions: ["read"],
        name: "Demo",
        organizationId: 1005336,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
    },
    {
        product: "Product.Dato",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        permissions: ["read"],
        name: "Demo",
        organizationId: 1005336,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
    },
    {
        product: "Product.AmsmReporting",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        permissions: ["read"],
        name: "Demo",
        organizationId: 1005336,
        agbCode: null,
        cicCode: 10003,
        mrdmUri: "demo",
        position: "Unknown",
    },
    {
        product: "Product.DlcaS",
        applicationInstance: "ApplicationInstance.CodmanExploration.NL",
        permissions: ["read"],
        name: "Academisch Medisch Centrum",
        position: "Unknown",
        organizationId: 1000080,
        agbCode: "06020702",
        cicCode: 103,
        mrdmUri: "amc",
    },
];

export const FAKE_PROFILE: AuthorizationProfile = {
    accountId: 666,
    email: "sheev.palpatine@logex.com",
    firstName: "Sheev",
    lastName: "Palpatine",
    organizations: [
        {
            name: "Logex Default",
            organizationId: -1,
            agbCode: null,
            cicCode: null,
            mrdmUri: null,
            position: "Unknown",
            product: "Product.Dcra",
        },
        {
            name: "demo",
            organizationId: 1005336,
            agbCode: null,
            cicCode: 10003,
            mrdmUri: "demo",
            position: "Unknown",
            product: "Product.Dcra",
        },
    ],
    isDisabled: false,
    isInternal: true,
    metadata: { UserId: "waad|VpWlz_jdruQYtliflHBTIM2vCfeYpQapwKO11ESc666" },
    impersonation: {
        originalUserName: "impersonationName",
        originalUserEmail: "impersonationEmail",
    },
};

export const FAKE_OVERVIEW_PERMISSIONS: string[] = ["Dbir", "Daci", "Dato"];
