import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
} from "@angular/core";

import { LgTranslatePipe } from "@logex/framework/lg-localization";

@Component({
    selector: "codman-labelled-input",
    templateUrl: "./labelled-input.component.html",
    styleUrls: ["./labelled-input.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, LgTranslatePipe],
})
export class SharedLabelledInputComponent {
    @ContentChildren("content") elements!: QueryList<ElementRef>;

    @Input() labelLc = "";
    @Input() showPlaceholder = false;

    @Output() readonly inputClick = new EventEmitter();

    _emitInputClick(e: MouseEvent): void {
        this.inputClick.emit();
    }
}
