import { IStatus } from "@codman/shared/types";
import {
    FilterCounts,
    Filters,
    IOutcome,
    IPatientCount,
    IRegistryConfiguration,
    IRegistryStructure,
    ISubsetConfiguration,
    ITrendMoment,
    MedicalItems,
    OutcomesConfiguration,
    IOutcomeTrend,
    PatientList,
    TimeRangeFilterRange,
    ISection,
    IProvider,
    ReferenceOutcome,
    IBenchmarkConfiguration,
    IEventAnalysis,
    OutcomeWithConfiguration,
    ICategoryFilterComparisonUniversal,
    IStringFilterComparisonUniversal,
    FilterComparisonUniversal,
    IRangeFilterComparisonUniversal,
    OutcomeWithConfigurationUniversal,
} from "./data-access-api.types";

export const fakeRegistryStructure: IRegistryStructure = {
    id: "Dato",
    subsets: [
        {
            id: "bariatricSurgery",
            pages: [
                { id: "clinicalOutcomes", sections: [{ id: "registryItems" }] },
                { id: "dominikTher", sections: [{ id: "registryItems" }, { id: "vojta" }] },
            ],
        },
        {
            id: "honza",
            pages: [{ id: "clinicalOutcomes", sections: [{ id: "registryItems" }] }],
        },
    ],
};

export const fakeRegistryConfiguration: IRegistryConfiguration = {
    moments: {},
    benchmarkTypes: ["national", "connect"],
    providersCountThreshold: 10,
};

export const fakeBenchmarkConfiguration: IBenchmarkConfiguration = {
    defaultRegion: "nl",
    regions: ["nl"],
    connect: [
        {
            id: 90001,
            name: "Test ziekenhuis F",
        },
        {
            id: 90002,
            name: "Test ziekenhuis G",
        },
    ],
    providerTypes: [],
};

export const fakeMedicalParameters: MedicalItems = {
    gender: {
        medicalParameterType: "Categorical",
        options: {
            "1": "male",
            "2": "female",
            "3": "undifferentiated",
        },
    },
    age: {
        medicalParameterType: "Numerical",
    },
    bmi: {
        medicalParameterType: "Categorical",
        options: {
            "1": "overweight",
            "2": "obesityClassI",
            "3": "obesityClassII",
            "4": "obesityClassIII",
            "5": "obesityClassIV",
            "6": "obesityClassV",
        },
    },
    charlsonComorbidityIndex: {
        medicalParameterType: "Categorical",
        options: {
            "1": "score0",
            "2": "score1",
            "3": "score2",
        },
    },
    asaScore: {
        medicalParameterType: "Categorical",
        options: {
            "1": "asaScoreI",
            "2": "asaScoreII",
            "3": "asaScoreIII",
            "4": "asaScoreIV",
            "5": "asaScoreV",
            "9": "unknown",
        },
    },
    diabetesMellitus: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    dyslipidemia: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    gastroOesophagealReflux: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    obstructiveSleepApnea: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    osteoarthritis: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    typeOfBariatricProcedure: {
        medicalParameterType: "Categorical",
        options: {
            "1": "gastricBand",
            "2": "rouxEnYGastricBypass",
            "3": "oneAnastomosisGastricBypass",
            "4": "bandedGastricBypass",
            "5": "sleeveGastrectomy",
            "6": "duodenalSwitch",
            "7": "bilioPancreaticDiversion(BPD)",
            "8": "singleAnastomosisDuodenalIlealBypass(SADI-s)",
            "9": "gastricPlication",
            "10": "verticalBandedGastroplasty",
            "11": "overigeProcedures",
        },
    },
    clavienDindo: {
        medicalParameterType: "Categorical",
        options: {
            "1": "degreeI",
            "2": "degreeII",
            "3": "degreeIII",
            "4": "degreeIV",
            "5": "degreeV",
        },
    },
    surgeon: {
        medicalParameterType: "String",
    },
    complicationsWithin30Days: {
        medicalParameterType: "Numerical",
    },
    severePostoperativeComplications: {
        medicalParameterType: "Numerical",
    },
    totalWeightLossFirstYearFollowUp: {
        medicalParameterType: "Numerical",
    },
    totalWeightLossSecondYearFollowUp: {
        medicalParameterType: "Numerical",
    },
    totalWeightLossThirdYearFollowUp: {
        medicalParameterType: "Numerical",
    },
    totalWeightLossFourthYearFollowUp: {
        medicalParameterType: "Numerical",
    },
    totalWeightLossFifthYearFollowUp: {
        medicalParameterType: "Numerical",
    },
    textbookOutcome: {
        medicalParameterType: "Numerical",
    },
    lengthOfStay: {
        medicalParameterType: "Numerical",
        unit: "days",
    },
    hypertension: {
        medicalParameterType: "Categorical",
        options: {
            "0": "none",
            "1": "present",
            "2": "presentWithMedication",
        },
    },
    bariatricSurgery: {
        medicalParameterType: "Categorical",
        options: {
            "1": "primary",
            "2": "twoStageSurgeryFirstProcedure",
            "3": "twoStageSurgerySecondProcedure",
            "4": "revisional",
        },
    },
};

export const fakeYearsFilterCount: TimeRangeFilterRange = {
    minYear: 2015,
    maxYear: 2020,
    minMonth: 1,
    maxMonth: 12,
};

export const fakeFilters: Filters = [
    {
        groupId: "population",
        filters: [
            {
                filterType: "Range",
                outcomeCode: "lengthOfStay",
                minValue: 1,
                maxValue: 365,
            },
            {
                filterType: "HistogramRange",
                outcomeCode: "age",
                minValue: 1,
                maxValue: 130,
            },
            {
                filterType: "Category",
                outcomeCode: "gender",
            },
            {
                filterType: "Category",
                outcomeCode: "bmi",
            },
            {
                filterType: "Category",
                outcomeCode: "charlsonComorbidityIndex",
            },
            {
                filterType: "Category",
                outcomeCode: "asaScore",
            },
            {
                filterType: "String",
                outcomeCode: "surgeon",
            },
        ],
    },
    {
        groupId: "comorbidity",
        filters: [
            {
                filterType: "Category",
                outcomeCode: "hypertension",
            },
            {
                filterType: "Category",
                outcomeCode: "diabetesMellitus",
            },
            {
                filterType: "Category",
                outcomeCode: "dyslipidemia",
            },
            {
                filterType: "Category",
                outcomeCode: "gastroOesophagealReflux",
            },
            {
                filterType: "Category",
                outcomeCode: "obstructiveSleepApnea",
            },
            {
                filterType: "Category",
                outcomeCode: "osteoarthritis",
            },
        ],
    },
    {
        groupId: "treatment",
        filters: [
            {
                filterType: "Category",
                outcomeCode: "bariatricSurgery",
            },
            {
                filterType: "Category",
                outcomeCode: "typeOfBariatricProcedure",
            },
            {
                filterType: "Category",
                outcomeCode: "clavienDindo",
            },
            {
                filterType: "String",
                outcomeCode: "surgeon",
            },
        ],
    },
];

export const fakePatientListItems = [
    "complicationsWithin30Days",
    "severePostoperativeComplications",
    "totalWeightLossFirstYearFollowUp",
    "totalWeightLossSecondYearFollowUp",
    "totalWeightLossThirdYearFollowUp",
    "totalWeightLossFourthYearFollowUp",
    "totalWeightLossFifthYearFollowUp",
    "textbookOutcome",
    "lengthOfStay",
];

export const fakeSubsetConfiguration: ISubsetConfiguration = {
    analysisUnit: "Patients",
    patientListItems: fakePatientListItems,
    filterGroups: fakeFilters,
    medicalParameters: fakeMedicalParameters,
    defaultNumberOfYearsSelected: 3,
};

export const fakePageOutcomes: ISection[] = [
    {
        sectionId: "outcomes",
        outcomes: [
            "complications30Days",
            "severeComplications",
            "totalWeightLoss12Months",
            "totalWeightLoss24Months",
            "totalWeightLoss36Months",
            "totalWeightLoss48Months",
            "totalWeightLoss60Months",
            "textbookOutcome",
            "lengthOfStay",
            "diabetesImprovementWithinOneYear",
            "hypertensionImprovementWithinOneYear",
        ],
    },
];

export const fakeOutcomesConfiguration: OutcomesConfiguration = {
    asaScore: { outcomeType: "SingleMeasurement" },
    lengthOfStay: { maxValue: 5, decimalPoints: 0, outcomeType: "SingleMeasurement" },
    complicationsWithin30Days: { outcomeType: "SingleMeasurement" },
    severePostoperativeComplications: { outcomeType: "SingleMeasurement" },
    textbookOutcome: { outcomeType: "SingleMeasurement" },
    totalWeightLossFifthYearFollowUp: { outcomeType: "SingleMeasurement" },
    totalWeightLossFirstYearFollowUp: { outcomeType: "SingleMeasurement" },
    totalWeightLossFourthYearFollowUp: { outcomeType: "SingleMeasurement" },
    totalWeightLossSecondYearFollowUp: { outcomeType: "SingleMeasurement" },
    totalWeightLossThirdYearFollowUp: { outcomeType: "SingleMeasurement" },
};

export const fakeSharedProviders: IProvider[] = [
    { id: 90001, name: "Test Ziekehuis A" },
    { id: 90002, name: "Test Ziekehuis B" },
    { id: 90003, name: "Test Ziekehuis C" },
    { id: 90004, name: "Test Ziekehuis D" },
];

export const fakeFilterCounts: FilterCounts = {
    "1": 4,
    "2": 17,
    "3": 175,
    "4": 224,
    "5": 92,
    "6": 53,
};

export const fakeSingleOutcome: OutcomeWithConfigurationUniversal = {
    outcomeId: "complicationsWithin30Days",
    medicalParameterId: "complicationsWithin30Days",
    calculationType: "Percentage",
    unit: "",
    formatter: "percent",
    outcomeType: "SingleMeasurement",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Percentage",
            percentage: 0.04781705,
            numerator: 46,
            denominator: 962,
            numberOfPatients: 962,
            confidenceIntervalLower: 3.4333005,
            confidenceIntervalUpper: 6.1301093,
            numberOfProviders: 1,
            anonymized: false,
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Percentage",
            percentage: 0.057054744,
            numerator: 296,
            denominator: 5188,
            numberOfPatients: 5188,
            confidenceIntervalLower: 5.074306,
            confidenceIntervalUpper: 6.3366427,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
};

export const fakeDistributionPercentageOutcome: OutcomeWithConfigurationUniversal = {
    outcomeId: "diabetesImprovementWithinOneYear",
    medicalParameterId: "diabetesImprovementWithinOneYear",
    calculationType: "DistributionPercentage",
    unit: "",
    formatter: "percent",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "DistributionPercentage",
            numberOfPatients: 962,
            distribution: {
                normotensive: {
                    percentage: 0.015592515,
                    numerator: 15,
                    denominator: 962,
                    confidenceIntervalLower: 0.7763375,
                    confidenceIntervalUpper: 2.3421657,
                },
                improvement: {
                    percentage: 0.006237006,
                    numerator: 6,
                    denominator: 962,
                    confidenceIntervalLower: 0.12619495,
                    confidenceIntervalUpper: 1.1212063,
                },
                notApplicable: {
                    percentage: 0.14345114,
                    numerator: 138,
                    denominator: 962,
                    confidenceIntervalLower: 12.129997,
                    confidenceIntervalUpper: 16.56023,
                },
            },
            numberOfProviders: 1,
            anonymized: false,
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "DistributionPercentage",
            numberOfPatients: 5188,
            distribution: {
                normotensive: {
                    percentage: 0.016962221,
                    numerator: 88,
                    denominator: 5188,
                    confidenceIntervalLower: 1.3448377,
                    confidenceIntervalUpper: 2.0476065,
                },
                improvement: {
                    percentage: 0.0057825756,
                    numerator: 30,
                    denominator: 5188,
                    confidenceIntervalLower: 0.3719299,
                    confidenceIntervalUpper: 0.78458524,
                },
                notApplicable: {
                    percentage: 0.13916731,
                    numerator: 722,
                    denominator: 5188,
                    confidenceIntervalLower: 12.974875,
                    confidenceIntervalUpper: 14.858586,
                },
                equal: {
                    percentage: 0.00077101,
                    numerator: 4,
                    denominator: 5188,
                    confidenceIntervalLower: 0.0015711517,
                    confidenceIntervalUpper: 0.15263085,
                },
                deNovo: {
                    percentage: 0.0001927525,
                    numerator: 1,
                    denominator: 5188,
                    confidenceIntervalLower: 0.0,
                    confidenceIntervalUpper: 0.0570511,
                },
            },
            numberOfProviders: 9,
            anonymized: false,
        },
    },
};

export const fakeMedianOutcome: OutcomeWithConfigurationUniversal = {
    outcomeId: "lengthOfStay",
    medicalParameterId: "lengthOfStay",
    calculationType: "Median",
    unit: "days",
    formatter: "int",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Median",
            numberOfPatients: 958,
            median: 1.0,
            confidenceIntervalLower: 1.0,
            confidenceIntervalUpper: 1.0,
            numberOfProviders: 1,
            anonymized: false,
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Median",
            numberOfPatients: 5157,
            median: 1.0,
            confidenceIntervalLower: 1.0,
            confidenceIntervalUpper: 2.0,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
};

export const fakeAverageOutcome: OutcomeWithConfigurationUniversal = {
    medicalParameterId: "averageWeightLoss60Months",
    outcomeId: "averageWeightLoss60Months",
    unit: "kg",
    calculationType: "Average",
    formatter: "float",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Average",
            numberOfPatients: 13,
            average: 19.538462,
            confidenceIntervalLower: 12.206005,
            confidenceIntervalUpper: 26.870918,
            numberOfProviders: 1,
            anonymized: false,
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Average",
            numberOfPatients: 62,
            average: 23.612904,
            confidenceIntervalLower: 21.059351,
            confidenceIntervalUpper: 26.166456,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
};

export const fakeCountOutcome: OutcomeWithConfigurationUniversal = {
    outcomeId: "abCourse",
    medicalParameterId: "abCourse",
    unit: "",
    calculationType: "Count",
    formatter: "float",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Count",
            anonymized: false,
            count: 47,
            numberOfPatients: 626,
            numberOfProviders: 9,
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Count",
            anonymizationType: ["NumberOfPatients"],
            anonymized: true,
        },
    },
};

export const fakeSectionOutcomes: OutcomeWithConfigurationUniversal[] = [
    fakeSingleOutcome,
    fakeMedianOutcome,
    fakeAverageOutcome,
    fakeDistributionPercentageOutcome,
    fakeCountOutcome,
];

export const fakeOtherOutcomeProviders: ReferenceOutcome[] = [
    {
        type: "Percentage",
        percentage: 0.24147726,
        numerator: 85,
        denominator: 352,
        numberOfPatients: 352,
        confidenceIntervalLower: 19.6767,
        confidenceIntervalUpper: 28.618752,
        numberOfProviders: 1,
        anonymized: false,
    },
    {
        type: "Percentage",
        percentage: 0.19320114,
        numerator: 341,
        denominator: 1765,
        numberOfPatients: 1765,
        confidenceIntervalLower: 17.478191,
        confidenceIntervalUpper: 21.162037,
        numberOfProviders: 1,
        anonymized: false,
    },
    {
        type: "Percentage",
        percentage: 0.19063005,
        numerator: 118,
        denominator: 619,
        numberOfPatients: 619,
        confidenceIntervalLower: 15.96858,
        confidenceIntervalUpper: 22.15743,
        numberOfProviders: 1,
        anonymized: false,
    },
    {
        type: "Percentage",
        percentage: 0.20633484,
        numerator: 228,
        denominator: 1105,
        numberOfPatients: 1105,
        confidenceIntervalLower: 18.247433,
        confidenceIntervalUpper: 23.019535,
        numberOfProviders: 1,
        anonymized: false,
    },
];

export const fakeOutcomeTrend: IOutcomeTrend[] = [
    {
        year: 2015,
        ownProvider: {
            type: "Percentage",
            percentage: 0.07751938,
            numerator: 10,
            denominator: 129,
            numberOfPatients: 129,
            confidenceIntervalLower: 3.1372216,
            confidenceIntervalUpper: 12.366654,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.05602241,
            numerator: 40,
            denominator: 714,
            numberOfPatients: 714,
            confidenceIntervalLower: 3.9154212,
            confidenceIntervalUpper: 7.289061,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2016,
        ownProvider: {
            type: "Percentage",
            percentage: 0.08029197,
            numerator: 11,
            denominator: 137,
            numberOfPatients: 137,
            confidenceIntervalLower: 3.4787207,
            confidenceIntervalUpper: 12.579673,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.081659975,
            numerator: 61,
            denominator: 747,
            numberOfPatients: 747,
            confidenceIntervalLower: 6.2021756,
            confidenceIntervalUpper: 10.12982,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2017,
        ownProvider: {
            type: "Percentage",
            percentage: 0.063694267,
            numerator: 10,
            denominator: 157,
            numberOfPatients: 157,
            confidenceIntervalLower: 2.54941,
            confidenceIntervalUpper: 10.189444,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.069008784,
            numerator: 55,
            denominator: 797,
            numberOfPatients: 797,
            confidenceIntervalLower: 5.1411247,
            confidenceIntervalUpper: 8.660632,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2018,
        ownProvider: {
            type: "Percentage",
            percentage: 0.019108281,
            numerator: 3,
            denominator: 157,
            numberOfPatients: 157,
            confidenceIntervalLower: 0.0,
            confidenceIntervalUpper: 4.0523753,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.053140097,
            numerator: 44,
            denominator: 828,
            numberOfPatients: 828,
            confidenceIntervalLower: 3.7861097,
            confidenceIntervalUpper: 6.84191,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2019,
        ownProvider: {
            type: "Percentage",
            percentage: 0.037500002,
            numerator: 6,
            denominator: 160,
            numberOfPatients: 160,
            confidenceIntervalLower: 0.8061744,
            confidenceIntervalUpper: 6.693826,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.047619047,
            numerator: 38,
            denominator: 798,
            numberOfPatients: 798,
            confidenceIntervalLower: 3.284327,
            confidenceIntervalUpper: 6.2394824,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2020,
        ownProvider: {
            type: "Percentage",
            percentage: 0.034883723,
            numerator: 3,
            denominator: 86,
            numberOfPatients: 86,
            confidenceIntervalLower: 0.0,
            confidenceIntervalUpper: 7.366375,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.05586592,
            numerator: 30,
            denominator: 537,
            numberOfPatients: 537,
            confidenceIntervalLower: 3.6441002,
            confidenceIntervalUpper: 7.529084,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2021,
        ownProvider: {
            type: "Percentage",
            percentage: 0.0088495576,
            numerator: 1,
            denominator: 113,
            numberOfPatients: 113,
            confidenceIntervalLower: 0.0,
            confidenceIntervalUpper: 2.6117773,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.0352,
            numerator: 22,
            denominator: 625,
            numberOfPatients: 625,
            confidenceIntervalLower: 2.0752056,
            confidenceIntervalUpper: 4.9647946,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
    {
        year: 2022,
        ownProvider: {
            type: "Percentage",
            percentage: 0.08695652,
            numerator: 2,
            denominator: 23,
            numberOfPatients: 23,
            confidenceIntervalLower: 0.0,
            confidenceIntervalUpper: 20.211315,
            numberOfProviders: 1,
            anonymized: false,
        },
        nationalBenchmark: {
            type: "Percentage",
            percentage: 0.04225352,
            numerator: 6,
            denominator: 142,
            numberOfPatients: 142,
            confidenceIntervalLower: 0.91656625,
            confidenceIntervalUpper: 7.5341372,
            numberOfProviders: 9,
            anonymized: false,
        },
    },
];

export const fakeEventAnalysis: IEventAnalysis = {
    eventName: "deceased",
    intervals: [
        {
            interval: 0,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 1,
                confidenceIntervalLower: 1,
                confidenceIntervalUpper: 1,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 55,
                eventCount: 0,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 1,
                confidenceIntervalLower: 1,
                confidenceIntervalUpper: 1,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 679,
                eventCount: 0,
                censoredCount: 0,
            },
        },
        {
            interval: 1,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.6727273,
                confidenceIntervalLower: 0.54871947,
                confidenceIntervalUpper: 0.7967351,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 37,
                eventCount: 18,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.59499264,
                confidenceIntervalLower: 0.5580687,
                confidenceIntervalUpper: 0.6319166,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 404,
                eventCount: 275,
                censoredCount: 0,
            },
        },
        {
            interval: 2,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.4909091,
                confidenceIntervalLower: 0.35878778,
                confidenceIntervalUpper: 0.6230304,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 27,
                eventCount: 10,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.45949927,
                confidenceIntervalLower: 0.4220139,
                confidenceIntervalUpper: 0.49698463,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 312,
                eventCount: 92,
                censoredCount: 0,
            },
        },
        {
            interval: 3,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.34545457,
                confidenceIntervalLower: 0.21978211,
                confidenceIntervalUpper: 0.47112703,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 19,
                eventCount: 8,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.33578792,
                confidenceIntervalLower: 0.30026513,
                confidenceIntervalUpper: 0.3713107,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 228,
                eventCount: 84,
                censoredCount: 0,
            },
        },
        {
            interval: 4,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.23636366,
                confidenceIntervalLower: 0.12408212,
                confidenceIntervalUpper: 0.3486452,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 13,
                eventCount: 6,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.2179676,
                confidenceIntervalLower: 0.18691275,
                confidenceIntervalUpper: 0.24902245,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 148,
                eventCount: 80,
                censoredCount: 0,
            },
        },
        {
            interval: 5,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.12727275,
                confidenceIntervalLower: 0.039191857,
                confidenceIntervalUpper: 0.21535365,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 7,
                eventCount: 6,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.13991164,
                confidenceIntervalLower: 0.1138189,
                confidenceIntervalUpper: 0.16600437,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 95,
                eventCount: 53,
                censoredCount: 0,
            },
        },
        {
            interval: 6,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.10909094,
                confidenceIntervalLower: 0.026698804,
                confidenceIntervalUpper: 0.19148308,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 6,
                eventCount: 1,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.089838,
                confidenceIntervalLower: 0.068329476,
                confidenceIntervalUpper: 0.11134652,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 74,
                eventCount: 21,
                censoredCount: 0,
            },
        },
        {
            interval: 7,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.03636367,
                confidenceIntervalLower: 0,
                confidenceIntervalUpper: 0.08583631,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 2,
                eventCount: 4,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.04712813,
                confidenceIntervalLower: 0.031188488,
                confidenceIntervalUpper: 0.06306777,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 32,
                eventCount: 42,
                censoredCount: 0,
            },
        },
        {
            interval: 8,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 0.018181851,
                confidenceIntervalLower: 0,
                confidenceIntervalUpper: 0.053492792,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 1,
                eventCount: 1,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.013254788,
                confidenceIntervalLower: 0.0046525765,
                confidenceIntervalUpper: 0.021856999,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 9,
                eventCount: 23,
                censoredCount: 0,
            },
        },
        {
            interval: 9,
            ownProvider: {
                type: "EventAnalysis",
                remainingPercentage: 3.3527613e-8,
                confidenceIntervalLower: 0,
                confidenceIntervalUpper: 0.0000484258,
                numberOfPatients: 55,
                anonymized: false,
                numerator: 0,
                eventCount: 1,
                censoredCount: 0,
            },
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 0.0029455097,
                confidenceIntervalLower: 0,
                confidenceIntervalUpper: 0.0070217606,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 2,
                eventCount: 7,
                censoredCount: 0,
            },
        },
        {
            interval: 10,
            nationalBenchmark: {
                type: "EventAnalysis",
                remainingPercentage: 1.6298145e-9,
                confidenceIntervalLower: 0,
                confidenceIntervalUpper: 0.0000030382487,
                numberOfPatients: 679,
                anonymized: false,
                numerator: 0,
                eventCount: 0,
                censoredCount: 0,
            },
        },
    ],
};

export const fakeRangeFilterComparison: IRangeFilterComparisonUniversal = {
    filterName: "age",
    filterType: "HistogramRange",
    comparisonData: [
        {
            filterValue: "69",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0,
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        percentage: 0.0625,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "70",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0,
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        percentage: 0.071428576,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "72",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        anonymizationType: ["NumberOfPatients"],
                        anonymized: true,
                    },
                },
            },
        },
        {
            filterValue: "73",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        anonymizationType: ["NumberOfPatients"],
                        anonymized: true,
                    },
                },
            },
        },
        {
            filterValue: "76",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        anonymizationType: ["NumberOfPatients"],
                        anonymized: true,
                    },
                },
            },
        },
    ],
};

export const fakeCategoryFilterComparison: ICategoryFilterComparisonUniversal = {
    filterName: "gender",
    filterType: "Category",
    comparisonData: [
        {
            filterValue: "male",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.050209208,
                        numerator: 12,
                        denominator: 239,
                        numberOfPatients: 239,
                        confidenceIntervalLower: 2.2523043,
                        confidenceIntervalUpper: 7.7895374,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        percentage: 0.050127444,
                        numerator: 59,
                        denominator: 1177,
                        numberOfPatients: 1177,
                        confidenceIntervalLower: 3.7661123,
                        confidenceIntervalUpper: 6.2593765,
                        numberOfProviders: 9,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "female",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.047091413,
                        numerator: 34,
                        denominator: 722,
                        numberOfPatients: 722,
                        confidenceIntervalLower: 3.1639438,
                        confidenceIntervalUpper: 6.2543387,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
                second: {
                    sourceType: "nationalBenchmark",
                    data: {
                        type: "Percentage",
                        percentage: 0.059102244,
                        numerator: 237,
                        denominator: 4010,
                        numberOfPatients: 4010,
                        confidenceIntervalLower: 5.180335,
                        confidenceIntervalUpper: 6.640114,
                        numberOfProviders: 9,
                        anonymized: false,
                    },
                },
            },
        },
    ],
};

export const fakeStringFilterComparison: IStringFilterComparisonUniversal = {
    filterName: "surgeon",
    filterType: "String",
    comparisonData: [
        {
            filterValue: "0",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "1",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.05982906,
                        numerator: 14,
                        denominator: 234,
                        numberOfPatients: 234,
                        confidenceIntervalLower: 2.9440672,
                        confidenceIntervalUpper: 9.021745,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "10",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 13,
                        numberOfPatients: 13,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "11",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.09523809,
                        numerator: 2,
                        denominator: 21,
                        numberOfPatients: 21,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 22.078873,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "12",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 11,
                        numberOfPatients: 11,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "13",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 5,
                        numberOfPatients: 5,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "14",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 1,
                        numberOfPatients: 1,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "15",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 1,
                        numberOfPatients: 1,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "2",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.036585364,
                        numerator: 6,
                        denominator: 164,
                        numberOfPatients: 164,
                        confidenceIntervalLower: 0.78514713,
                        confidenceIntervalUpper: 6.5319257,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "23",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "3",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.04188482,
                        numerator: 8,
                        denominator: 191,
                        numberOfPatients: 191,
                        confidenceIntervalLower: 1.347447,
                        confidenceIntervalUpper: 7.0295167,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "4",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.067226896,
                        numerator: 8,
                        denominator: 119,
                        numberOfPatients: 119,
                        confidenceIntervalLower: 2.223419,
                        confidenceIntervalUpper: 11.22196,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "5",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.071428576,
                        numerator: 4,
                        denominator: 56,
                        numberOfPatients: 56,
                        confidenceIntervalLower: 0.39748853,
                        confidenceIntervalUpper: 13.8882265,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "6",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.04347826,
                        numerator: 3,
                        denominator: 69,
                        numberOfPatients: 69,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 9.159709,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "7",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.029411764,
                        numerator: 1,
                        denominator: 34,
                        numberOfPatients: 34,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 8.620475,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "8",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 20,
                        numberOfPatients: 20,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "9",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 22,
                        numberOfPatients: 22,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
        {
            filterValue: "99",
            outcome: {
                first: {
                    sourceType: "ownProvider",
                    data: {
                        type: "Percentage",
                        percentage: 0.0,
                        numerator: 0,
                        denominator: 1,
                        numberOfPatients: 1,
                        confidenceIntervalLower: 0.0,
                        confidenceIntervalUpper: 0.0,
                        numberOfProviders: 1,
                        anonymized: false,
                    },
                },
            },
        },
    ],
};

export const fakeFilterComparisons: FilterComparisonUniversal[] = [
    fakeRangeFilterComparison,
    fakeCategoryFilterComparison,
    fakeStringFilterComparison,
];

export const fakePatientCount: IPatientCount = {
    numerator: 630,
    denominator: 630,
    connectBenchmarkNumerator: 630,
    connectBenchmarkDenominator: 630,
    nationalBenchmarkNumerator: 630,
    nationalBenchmarkDenominator: 630,
    providerTypeBenchmarkNumerator: 630,
    providerTypeBenchmarkDenominator: 630,
};

export const fakePatientTrend: ITrendMoment[] = [
    {
        year: 2015,
        quarter: 1,
        month: 1,
        count: 9,
    },
    {
        year: 2015,
        quarter: 1,
        month: 2,
        count: 6,
    },
    {
        year: 2015,
        quarter: 1,
        month: 3,
        count: 10,
    },
    {
        year: 2015,
        quarter: 2,
        month: 4,
        count: 11,
    },
    {
        year: 2015,
        quarter: 2,
        month: 5,
        count: 10,
    },
    {
        year: 2015,
        quarter: 2,
        month: 6,
        count: 12,
    },
    {
        year: 2015,
        quarter: 3,
        month: 7,
        count: 2,
    },
    {
        year: 2015,
        quarter: 3,
        month: 8,
        count: 6,
    },
    {
        year: 2015,
        quarter: 3,
        month: 9,
        count: 7,
    },
    {
        year: 2015,
        quarter: 4,
        month: 10,
        count: 13,
    },
    {
        year: 2015,
        quarter: 4,
        month: 11,
        count: 12,
    },
    {
        year: 2015,
        quarter: 4,
        month: 12,
        count: 7,
    },
    {
        year: 2016,
        quarter: 1,
        month: 1,
        count: 9,
    },
];

export const fakePatientList: PatientList = [
    {
        upn: "test-00375d6a26cf6081",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: "0",
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-00997dbf23ac5419",
        medicalParameters: {
            complicationsWithin30Days: "1",
            severePostoperativeComplications: "2",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "0",
            lengthOfStay: "12",
        },
    },
    {
        upn: "test-019975a62ad13986",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-01a54c934b9e1999",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-02b8ec10b10978d7",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "2",
        },
    },
    {
        upn: "test-02c0debe113334c7",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-02e6459f5871e813",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: "1",
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-033f5ee7cb9e2e0f",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: "0",
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
    {
        upn: "test-03d8436f25d3bf5a",
        medicalParameters: {
            complicationsWithin30Days: "0",
            severePostoperativeComplications: "0",
            totalWeightLossFirstYearFollowUp: null,
            totalWeightLossSecondYearFollowUp: null,
            totalWeightLossThirdYearFollowUp: null,
            totalWeightLossFourthYearFollowUp: null,
            totalWeightLossFifthYearFollowUp: null,
            textbookOutcome: "1",
            lengthOfStay: "1",
        },
    },
];

export const fakePromsPercentage: OutcomeWithConfigurationUniversal = {
    outcomeId: "promsPercentage",
    medicalParameterId: "promsPercentage",
    calculationType: "Percentage",
    unit: "",
    formatter: "percent",
    maxValue: 100,
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Percentage",
            anonymized: false,
            percentage: 0.6,
            numerator: 120,
            denominator: 200,
            numberOfPatients: 200,
            confidenceIntervalLower: 55,
            confidenceIntervalUpper: 65,
            moments: [
                {
                    type: "Percentage",
                    anonymized: false,
                    percentage: 0.5,
                    numerator: 50,
                    denominator: 100,
                    numberOfPatients: 100,
                    numberOfProviders: 1,
                    confidenceIntervalLower: 45,
                    confidenceIntervalUpper: 55,
                    momentId: "0",
                },
                {
                    type: "Percentage",
                    anonymized: false,
                    percentage: 0.7,
                    numerator: 70,
                    denominator: 100,
                    numberOfPatients: 100,
                    numberOfProviders: 1,
                    confidenceIntervalLower: 65,
                    confidenceIntervalUpper: 75,
                    momentId: "1",
                },
                {
                    type: "Percentage",
                    anonymized: false,
                    percentage: 0.8,
                    numerator: 80,
                    denominator: 100,
                    numberOfPatients: 100,
                    numberOfProviders: 1,
                    confidenceIntervalLower: 75,
                    confidenceIntervalUpper: 85,
                    momentId: "2",
                },
            ],
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Percentage",
            anonymized: false,
            percentage: 65,
            numerator: 1300,
            denominator: 2000,
            numberOfPatients: 2000,
            confidenceIntervalLower: 60,
            confidenceIntervalUpper: 65,
            moments: [
                {
                    type: "Percentage",
                    anonymized: false,
                    percentage: 0.55,
                    numerator: 550,
                    denominator: 1000,
                    numberOfPatients: 1000,
                    numberOfProviders: 1,
                    confidenceIntervalLower: 50,
                    confidenceIntervalUpper: 60,
                    momentId: "0",
                },
                {
                    type: "Percentage",
                    anonymized: false,
                    percentage: 0.75,
                    numerator: 750,
                    denominator: 1000,
                    numberOfPatients: 1000,
                    numberOfProviders: 1,
                    confidenceIntervalLower: 70,
                    confidenceIntervalUpper: 80,
                    momentId: "1",
                },
                {
                    type: "Percentage",
                    momentId: "2",
                    anonymized: true,
                    anonymizationType: ["NumberOfPatients"],
                },
            ],
        },
    },
};

export const fakePromsMedian: IOutcome = {
    outcomeId: "physicalFunctioning",
    medicalParameterId: "",
    ownProvider: {
        type: "Median",
        numberOfPatients: 65,
        median: 65.0,
        confidenceIntervalLower: 35.0,
        confidenceIntervalUpper: 85.0,
        moments: [
            {
                type: "Median",
                numberOfPatients: 43,
                median: 55.0,
                confidenceIntervalLower: 30.0,
                confidenceIntervalUpper: 75.0,
                momentId: "1",
                numberOfProviders: 1,
                anonymized: false,
            },
            {
                type: "Median",
                numberOfPatients: 22,
                median: 95.0,
                confidenceIntervalLower: 60.0,
                confidenceIntervalUpper: 100.0,
                momentId: "2",
                numberOfProviders: 1,
                anonymized: false,
            },
        ],
        numberOfProviders: 1,
        anonymized: false,
    },
    nationalBenchmark: {
        type: "Median",
        numberOfPatients: 304,
        median: 70.0,
        confidenceIntervalLower: 47.5,
        confidenceIntervalUpper: 90.0,
        moments: [
            {
                type: "Median",
                numberOfPatients: 197,
                median: 60.0,
                confidenceIntervalLower: 40.0,
                confidenceIntervalUpper: 75.0,
                momentId: "1",
                numberOfProviders: 8,
                anonymized: false,
            },
            {
                type: "Median",
                numberOfPatients: 107,
                median: 95.0,
                confidenceIntervalLower: 75.0,
                confidenceIntervalUpper: 100.0,
                momentId: "2",
                numberOfProviders: 8,
                anonymized: false,
            },
        ],
        numberOfProviders: 8,
        anonymized: false,
    },
};

export const fakePromsAverage: IOutcome = {
    outcomeId: "sf12GeneralHealth",
    medicalParameterId: "",
    ownProvider: {
        type: "Average",
        average: 54.773586,
        anonymizationType: ["Partial"],
        moments: [
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "0",
                anonymized: true,
            },
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "1",
                anonymized: true,
            },
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "10",
                anonymized: true,
            },
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "11",
                anonymized: true,
            },
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "12",
                anonymized: true,
            },
        ],
        anonymized: true,
    },
    nationalBenchmark: {
        type: "Average",
        average: 56.17158,
        anonymizationType: ["Partial"],
        moments: [
            {
                type: "Average",
                numberOfPatients: 15,
                average: 59.466667,
                confidenceIntervalLower: 40.563877,
                confidenceIntervalUpper: 78.36946,
                momentId: "0",
                numberOfProviders: 8,
                anonymized: false,
            },
            {
                type: "Average",
                numberOfPatients: 18,
                average: 58.72222,
                confidenceIntervalLower: 43.24482,
                confidenceIntervalUpper: 74.19962,
                momentId: "1",
                numberOfProviders: 7,
                anonymized: false,
            },
            {
                type: "Average",
                numberOfPatients: 20,
                average: 50.15,
                confidenceIntervalLower: 33.564373,
                confidenceIntervalUpper: 66.73563,
                momentId: "10",
                numberOfProviders: 7,
                anonymized: false,
            },
            {
                type: "Average",
                numberOfPatients: 24,
                average: 52.833332,
                confidenceIntervalLower: 34.641758,
                confidenceIntervalUpper: 71.0249,
                momentId: "11",
                numberOfProviders: 8,
                anonymized: false,
            },
            {
                type: "Average",
                anonymizationType: ["NumberOfPatients"],
                momentId: "12",
                anonymized: true,
            },
        ],
        numberOfProviders: 9,
        anonymized: true,
    },
};

export const fakePromsCount: OutcomeWithConfigurationUniversal = {
    outcomeId: "promsCount",
    medicalParameterId: "",
    unit: "",
    formatter: "int",
    calculationType: "Count",
    first: {
        sourceType: "ownProvider",
        data: {
            type: "Count",
            anonymized: false,
            numberOfPatients: 47,
            count: 47,
            moments: [
                {
                    type: "Count",
                    anonymized: false,
                    numberOfPatients: 22,
                    numberOfProviders: 1,
                    count: 22,
                    momentId: "1",
                },
                {
                    type: "Count",
                    anonymized: false,
                    numberOfPatients: 25,
                    numberOfProviders: 1,
                    count: 25,
                    momentId: "2",
                },
            ],
        },
    },
    second: {
        sourceType: "nationalBenchmark",
        data: {
            type: "Count",
            anonymized: false,
            numberOfPatients: 150,
            count: 53,
            moments: [
                {
                    type: "Count",
                    anonymized: false,
                    numberOfPatients: 75,
                    numberOfProviders: 1,
                    count: 28,
                    momentId: "1",
                },
                {
                    type: "Count",
                    anonymized: false,
                    numberOfPatients: 75,
                    numberOfProviders: 1,
                    count: 25,
                    momentId: "2",
                },
            ],
        },
    },
};

export const fakeMultiMeasurementOutcome: IOutcome = {
    outcomeId: "fakeMultiMeasurement",
    // maxValue: 100,
    // decimals: 0,
    medicalParameterId: "",
    ownProvider: {
        numberOfPatients: 300,
        anonymized: false,
        type: "Percentage",
        percentage: 66,
        numerator: 200,
        denominator: 300,
        confidenceIntervalLower: 61,
        confidenceIntervalUpper: 71,
        moments: [
            {
                numberOfPatients: 100,
                numberOfProviders: 1,
                confidenceIntervalLower: 45,
                confidenceIntervalUpper: 55,
                momentId: "0",
                momentName: "0",
                type: "Percentage",
                anonymized: false,
                percentage: 50,
                numerator: 50,
                denominator: 100,
            },
            {
                numberOfPatients: 100,
                numberOfProviders: 1,
                confidenceIntervalLower: 65,
                confidenceIntervalUpper: 75,
                momentId: "1",
                momentName: "1",
                type: "Percentage",
                anonymized: false,
                percentage: 0.7,
                numerator: 70,
                denominator: 100,
            },
            {
                numberOfPatients: 100,
                numberOfProviders: 1,
                confidenceIntervalLower: 75,
                confidenceIntervalUpper: 85,
                momentId: "2",
                momentName: "2",
                anonymized: false,
                type: "Percentage",
                percentage: 0.8,
                numerator: 80,
                denominator: 100,
            },
        ],
    },
    nationalBenchmark: {
        numberOfPatients: 3000,
        type: "Percentage",
        anonymized: false,
        percentage: 43,
        numerator: 1300,
        denominator: 3000,
        confidenceIntervalLower: 38,
        confidenceIntervalUpper: 48,
        moments: [
            {
                numberOfPatients: 1000,
                numberOfProviders: 1,
                confidenceIntervalLower: 50,
                confidenceIntervalUpper: 60,
                momentId: "0",
                momentName: "0",
                type: "Percentage",
                anonymized: false,
                percentage: 0.55,
                numerator: 550,
                denominator: 1000,
            },
            {
                numberOfPatients: 1000,
                numberOfProviders: 1,
                confidenceIntervalLower: 70,
                confidenceIntervalUpper: 80,
                momentId: "1",
                momentName: "1",
                type: "Percentage",
                anonymized: false,
                percentage: 0.75,
                numerator: 750,
                denominator: 1000,
            },
            {
                momentId: "2",
                anonymized: true,
                anonymizationType: ["NumberOfPatients"],
                type: "Percentage",
            },
        ],
    },
};

export const fakeStatus: IStatus = {
    version: "1234",
    stage: "test",
    buildTime: "2022-05-21 14:00:00 UTC",
};
