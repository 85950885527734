import { IFilterDefinition } from "@logex/framework/lg-filterset";
import { Range } from "@codman/shared/util-filters";
import { Observable } from "rxjs";

export interface IHistogramFilterDefinition extends IFilterDefinition {
    filterType: "histogram";
    min: number;
    max: number;
    default?: Range;
    source: () => Observable<number[]>;
}

export type HistogramFilterStorage = Record<string, Range>;
