import { Component, LOCALE_ID, OnChanges, SimpleChanges, inject } from "@angular/core";
import { CommonModule, FormStyle, TranslationWidth, getLocaleMonthNames } from "@angular/common";

import { Observable, Subject } from "rxjs";

import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgTranslatePipe, LgTranslateService } from "@logex/framework/lg-localization";
import { IDropdownDefinition, LgButton, LgDropdownComponent } from "@logex/framework/ui-core";

import { IOverlayPopup } from "@codman/shared/ui-overlay";

export interface ITimeRangePickerInitProps {
    minYear: number;
    maxYear: number;
    minMonth: number;
    maxMonth: number;
    selectedMinYear: number | null;
    selectedMaxYear: number | null;
    selectedMinMonth: number | null;
    selectedMaxMonth: number | null;
}

export interface ITimeRangePickerSelection {
    selectedMinYear: number | null;
    selectedMaxYear: number | null;
    selectedMinMonth: number | null;
    selectedMaxMonth: number | null;
}

enum YearBlockTypes {
    from = "from",
    to = "to",
}

enum CustomOption {
    Custom = 0,
    AllYears = 1,
    Last3Months = 2,
    Last6Months = 3,
    Last12Months = 4,
    Last2Years = 5,
    Last3Years = 6,
    Last4Years = 7,
    Last5Years = 8,
}

enum CustomType {
    Months = "months",
    Years = "years",
}

interface ICustomDropdownEntry {
    id: CustomOption;
    name: string;
    yearFrom: number;
    yearTo: number;
    monthFrom: number;
    monthTo: number;
    help: string;
    disabled: boolean;
}

@Component({
    selector: "codman-time-range-picker",
    templateUrl: "./time-range-picker.component.html",
    styleUrls: ["./time-range-picker.component.scss"],
    standalone: true,
    imports: [CommonModule, LgLayoutModule, LgDropdownComponent, LgButton, LgTranslatePipe],
})
export class SharedTimeRangePickerComponent
    implements OnChanges, IOverlayPopup<ITimeRangePickerInitProps, ITimeRangePickerSelection>
{
    private _lgTranslateService = inject(LgTranslateService);
    private _locale = inject(LOCALE_ID);

    // Year selection
    _yearMin: number | null = null;
    _yearMax: number | null = null;

    _currentYearFrom = 0;
    _currentYearTo = 0;

    _yearDropdownDefinitionFrom: IDropdownDefinition<number> | null = null;
    _yearDropdownDefinitionTo: IDropdownDefinition<number> | null = null;

    _currentMinYearFrom = 0;
    _currentMaxYearFrom = 0;
    _currentMinYearTo = 0;
    _currentMaxYearTo = 0;

    _singleYear: number | null = null;

    _allYearsEntries: Array<{ id: number; name: string }> = [];

    readonly yearBlocks = [YearBlockTypes.from, YearBlockTypes.to];
    readonly YearBlockTypes = YearBlockTypes;

    // Month selection
    _monthMin: number | null = null;
    _monthMax: number | null = null;

    _monthStart: number | null = null;
    _monthEnd: number | null = null;

    _blockStart: YearBlockTypes | null = null;
    _blockEnd: YearBlockTypes | null = null;

    readonly allMonths = Array(12)
        .fill(1)
        .map((x, i) => i + 1);

    _monthNames = [
        ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated),
    ];

    // Custom selection
    _customDropdownCurrent = 0;
    _customDropdownDefinition: IDropdownDefinition<number> | null = null;
    _customValues: Array<ICustomDropdownEntry | undefined> = [];

    // Other variables
    _selectionInProgress = false;
    private readonly _selection$ = new Subject<ITimeRangePickerSelection>();

    initialize(config: ITimeRangePickerInitProps): Observable<ITimeRangePickerSelection> {
        this._yearMin = config.minYear;
        this._monthMin = config.minMonth;
        this._yearMax = config.maxYear;
        this._monthMax = config.maxMonth;

        this._updateDropdownDefinitions();

        if (
            // a value has been selected earlier in the session
            config.selectedMinYear &&
            config.selectedMaxYear
        ) {
            this._selectSpecificRange(
                config.selectedMinYear,
                config.selectedMaxYear,
                config.selectedMinMonth,
                config.selectedMaxMonth,
            );
            this._adjustYearPickers(YearBlockTypes.from);
        } else {
            // the full available range is selected
            this._selectSpecificRange(this._yearMin, this._yearMax, null, null);
        }

        if (this._yearMin && this._yearMax) {
            this._currentMinYearFrom = this._yearMin;
            this._currentMaxYearFrom = this._yearMax;
            this._currentMinYearTo = this._yearMin;
            this._currentMaxYearTo = this._yearMax;
        }

        return this._selection$;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["min"] || changes["max"]) {
            this._updateDropdownDefinitions();
        }
    }

    _updateYearSelection(value: number, block: YearBlockTypes): void {
        if (this._selectionInProgress) this._resetAllProgress();

        this._selectSpecificRange(
            block === YearBlockTypes.from ? value : this._currentYearFrom,
            block === YearBlockTypes.to ? value : this._currentYearTo,
            this._monthStart,
            this._monthEnd,
        );
        this._adjustYearPickers(block);
    }

    _updateYearSelectionStep(block: YearBlockTypes, down: boolean): void {
        if (this._selectionInProgress) this._resetAllProgress();

        this._selectSpecificRange(
            block === YearBlockTypes.from
                ? this._currentYearFrom + (down ? -1 : 1)
                : this._currentYearFrom,
            block === YearBlockTypes.to
                ? this._currentYearTo + (down ? -1 : 1)
                : this._currentYearTo,
            this._monthStart,
            this._monthEnd,
        );
        this._adjustYearPickers(block);
    }

    _updateSelectionWithCustomRange(id: number): void {
        const currentCustomValue = this._customValues.find(value => value && value.id === id);
        if (currentCustomValue) {
            this._resetAllProgress();
            this._blockStart = YearBlockTypes.from;
            this._selectSpecificRange(
                currentCustomValue.yearFrom,
                currentCustomValue.yearTo,
                currentCustomValue.monthFrom,
                currentCustomValue.monthTo,
            );
        }
    }

    _cancelInProgressSelection(e: MouseEvent): void {
        if (this._selectionInProgress) this._resetAllProgress();
    }

    _onButtonMouseClick = (e: MouseEvent, value: number, block: YearBlockTypes): void => {
        e.stopPropagation();
        this._selectionInProgressStart(e, value, block);
    };

    _onButtonMouseOver = (e: MouseEvent, value: number, block: YearBlockTypes): void => {
        if (this._selectionInProgress) {
            this._selectionInProgressUpdate(value, block);
        }
    };

    _update(): void {
        if (!this._selectionInProgress) {
            const selectedYearFrom = this._singleYear ?? this._currentYearFrom;
            const selectedYearTo = this._singleYear ?? this._currentYearTo;

            let selectedMonthFrom =
                this._blockStart === YearBlockTypes.from ? this._monthStart : this._monthEnd;
            let selectedMonthTo =
                this._blockStart === YearBlockTypes.from ? this._monthEnd : this._monthStart;

            if (this._blockStart === this._blockEnd && this._monthStart && this._monthEnd) {
                selectedMonthFrom = Math.min(this._monthStart, this._monthEnd);
                selectedMonthTo = Math.max(this._monthStart, this._monthEnd);
            }

            if (this._monthStart === null || this._monthEnd === null) {
                selectedMonthFrom = selectedYearFrom === this._yearMin ? this._monthMin : 1;
                selectedMonthTo = selectedYearTo === this._yearMax ? this._monthMax : 12;
            }

            const selection: ITimeRangePickerSelection =
                selectedYearFrom && selectedMonthFrom && selectedYearTo && selectedMonthTo
                    ? {
                          selectedMinYear: selectedYearFrom,
                          selectedMaxYear: selectedYearTo,
                          selectedMinMonth: selectedMonthFrom,
                          selectedMaxMonth: selectedMonthTo,
                      }
                    : {
                          selectedMinYear: null,
                          selectedMaxYear: null,
                          selectedMinMonth: null,
                          selectedMaxMonth: null,
                      };

            this._selection$.next(selection);
        }
        this._selection$.complete();
    }

    _cancel(): void {
        this._selection$.complete();
    }

    _updatePosition(): void {
        // required in IOverlayPopup
    }

    _isCurrentMonthSelected(month: number, block: YearBlockTypes): boolean {
        if (
            this._monthStart === null ||
            this._monthEnd === null ||
            this._blockStart === null ||
            this._blockEnd === null
        )
            return false;

        if (this._blockStart === this._blockEnd) {
            if (block !== this._blockStart) return false;
            if (this._monthStart <= this._monthEnd) {
                return month >= this._monthStart && month <= this._monthEnd;
            } else {
                return month >= this._monthEnd && month <= this._monthStart;
            }
        } else if (this._blockStart === YearBlockTypes.from) {
            if (block === YearBlockTypes.from) return month >= this._monthStart;
            if (block === YearBlockTypes.to) return month <= this._monthEnd;
        } else if (this._blockStart === YearBlockTypes.to) {
            if (block === YearBlockTypes.from) return month >= this._monthEnd;
            if (block === YearBlockTypes.to) return month <= this._monthStart;
        }
        return false;
    }

    _isCurrentMonthSelectedFirst(month: number, block: YearBlockTypes): boolean {
        if (
            this._monthStart === null ||
            this._monthEnd === null ||
            this._blockStart === null ||
            this._blockEnd === null
        )
            return false;

        if (this._blockStart === this._blockEnd) {
            if (block !== this._blockStart) return false;
            if (this._monthStart <= this._monthEnd) {
                return month === this._monthStart;
            } else {
                return month === this._monthEnd;
            }
        } else if (this._blockStart === YearBlockTypes.from && block === YearBlockTypes.from) {
            return month === this._monthStart;
        } else if (this._blockStart === YearBlockTypes.to && block === YearBlockTypes.from)
            return month === this._monthEnd;
        return false;
    }

    _isCurrentMonthSelectedLast(month: number, block: YearBlockTypes): boolean {
        if (
            this._monthStart === null ||
            this._monthEnd === null ||
            this._blockStart === null ||
            this._blockEnd === null
        )
            return false;

        if (this._blockStart === this._blockEnd) {
            if (block !== this._blockStart) return false;
            if (this._monthStart <= this._monthEnd) {
                return month === this._monthEnd;
            } else {
                return month === this._monthStart;
            }
        } else if (this._blockEnd === YearBlockTypes.to && block === YearBlockTypes.to) {
            return month === this._monthEnd;
        } else if (this._blockEnd === YearBlockTypes.from && block === YearBlockTypes.to)
            return month === this._monthStart;
        return false;
    }

    _isCurrentMonthEnabled(month: number, block: YearBlockTypes): boolean {
        if (this._monthMin === null || this._monthMax === null) return false;
        if (block === YearBlockTypes.from && this._currentYearFrom === this._yearMin) {
            return month >= this._monthMin;
        }
        if (block === YearBlockTypes.to && this._currentYearTo === this._yearMax) {
            return month <= this._monthMax;
        }
        return true;
    }

    private _selectionInProgressStart(e: MouseEvent, month: number, block: YearBlockTypes): void {
        e.preventDefault();

        if (this._selectionInProgress) {
            this._selectionInProgressEnd(month, block);
        } else {
            this._resetAllProgress();
            this._selectionInProgress = true;
            this._customDropdownCurrent = 0;
            this._monthStart = month;
            this._blockStart = block;
        }
    }

    private _selectionInProgressUpdate(month: number, block: YearBlockTypes | null): void {
        if (!this._selectionInProgress) {
            return;
        }

        this._monthEnd = month;
        this._blockEnd = block;
    }

    private _selectionInProgressEnd(month: number | null, block: YearBlockTypes | null): void {
        this._selectionInProgress = false;
        this._blockEnd = block;

        this._selectSpecificRange(
            this._currentYearFrom,
            this._currentYearTo,
            this._monthStart,
            month,
        );

        if (month === null || block === null) {
            this._resetAllProgress();
        }
    }

    private _selectSpecificRange(
        yearFrom: number,
        yearTo: number,
        monthStart: number | null,
        monthEnd: number | null,
    ): void {
        this._currentYearFrom = yearFrom;
        this._currentYearTo = yearTo;
        this._monthStart = monthStart;
        this._monthEnd = monthEnd;

        if (this._currentYearFrom === this._currentYearTo && this._yearMax && this._yearMin) {
            this._singleYear = this._currentYearFrom;

            if (this._currentYearTo === this._yearMax) {
                this._currentYearFrom = Math.max(this._currentYearTo - 1, this._yearMin);
            } else {
                this._currentYearTo = Math.min(this._currentYearFrom + 1, this._yearMax);
            }
        }
        if (this._blockStart === null || this._blockEnd === null) {
            if (this._singleYear) {
                if (this._singleYear === this._currentYearFrom) {
                    this._blockStart = YearBlockTypes.from;
                    this._blockEnd = YearBlockTypes.from;
                } else {
                    this._blockStart = YearBlockTypes.to;
                    this._blockEnd = YearBlockTypes.to;
                }
            } else {
                this._blockStart = YearBlockTypes.from;
                this._blockEnd = YearBlockTypes.to;
            }
        }

        if (this._blockStart && this._blockStart === this._blockEnd) {
            this._singleYear =
                this._blockStart === YearBlockTypes.from
                    ? this._currentYearFrom
                    : this._currentYearTo;
        } else {
            this._singleYear = null;
        }

        this._removeDisabledMonthsFromSelection();
        this._updateCustomSelectionValue(yearFrom, yearTo, monthStart, monthEnd);
    }

    private _updateCustomSelectionValue(
        yearFrom: number,
        yearTo: number,
        monthStart: number | null,
        monthEnd: number | null,
    ): void {
        let monthFrom = monthStart;
        let monthTo = monthEnd;
        let yearFromCurrent = yearFrom;
        let yearToCurrent = yearTo;
        if (this._blockStart === this._blockEnd && monthStart && monthEnd) {
            monthFrom = Math.min(monthStart, monthEnd);
            monthTo = Math.max(monthStart, monthEnd);
            if (this._blockStart === YearBlockTypes.from) {
                yearFromCurrent = yearFrom;
                yearToCurrent = yearFrom;
            } else if (this._blockStart === YearBlockTypes.to) {
                yearFromCurrent = yearTo;
                yearToCurrent = yearTo;
            }
        } else {
            monthFrom = this._blockStart === YearBlockTypes.from ? monthStart : monthEnd;
            monthTo = this._blockStart === YearBlockTypes.from ? monthEnd : monthStart;
        }

        const currentCustomValue = this._customValues.find(
            value =>
                value &&
                value.yearFrom === yearFromCurrent &&
                value.yearTo === yearToCurrent &&
                (monthFrom === null || value.monthFrom === monthFrom) &&
                (monthTo === null || value.monthTo === monthTo),
        );
        if (currentCustomValue) {
            this._customDropdownCurrent = currentCustomValue.id;
        } else {
            this._customDropdownCurrent = 0;
        }
    }

    private _removeDisabledMonthsFromSelection(): void {
        if (this._monthStart && this._monthEnd && this._monthMin && this._monthMax) {
            const currentYearFrom = this._singleYear ?? this._currentYearFrom;
            const currentYearTo = this._singleYear ?? this._currentYearTo;
            if (currentYearFrom === this._yearMin) {
                if (this._blockStart === YearBlockTypes.from && this._monthStart < this._monthMin) {
                    this._monthStart = this._monthMin;
                }
                if (this._blockStart === YearBlockTypes.to && this._monthEnd < this._monthMin) {
                    this._monthEnd = this._monthMin;
                }
            }
            if (currentYearTo === this._yearMax) {
                if (this._blockStart === YearBlockTypes.from && this._monthEnd > this._monthMax) {
                    this._monthEnd = this._monthMax;
                }
                if (this._blockStart === YearBlockTypes.to && this._monthStart > this._monthMax) {
                    this._monthStart = this._monthMax;
                }
            }
        }
    }

    private _resetAllProgress(): void {
        this._selectionInProgress = false;
        this._monthStart = null;
        this._monthEnd = null;
        this._blockStart = null;
        this._blockEnd = null;
        this._singleYear = null;
        this._updateCustomSelectionValue(this._currentYearFrom, this._currentYearTo, null, null);
    }

    private _adjustYearPickers(block: YearBlockTypes): void {
        // the bottom year picker is adjusted to be at least one year ahead of the top year picker

        if (this._currentYearFrom > this._currentYearTo && block === YearBlockTypes.from) {
            this._currentYearTo = this._currentYearFrom + 1;
        }

        if (this._yearDropdownDefinitionTo?.groups?.length)
            this._yearDropdownDefinitionTo.groups[0].entries = this._allYearsEntries.filter(
                year => year.id > this._currentYearFrom,
            );

        this._currentMinYearTo = this._currentYearFrom + 1;
    }

    private _updateDropdownDefinitions(): void {
        this._updateYearDropdownDefinition();
        this._updateCustomDropdownDefinition();
    }

    private _updateYearDropdownDefinition(): void {
        if (this._yearMin && this._yearMax && this._yearMin <= this._yearMax) {
            const years = Array.from({ length: this._yearMax - this._yearMin + 1 }, (v, i) =>
                this._yearMin ? this._yearMin + i : 0,
            );
            this._allYearsEntries = years.map(year => ({
                id: year,
                name: year.toString(),
            }));

            this._yearDropdownDefinitionFrom = {
                groups: [
                    {
                        entries: [
                            ...this._allYearsEntries.filter(
                                year => this._yearMax && year.id < this._yearMax,
                            ),
                        ],
                    },
                ],
            };

            this._yearDropdownDefinitionTo = {
                groups: [
                    {
                        entries: [
                            ...this._allYearsEntries.filter(
                                year => this._yearMin && year.id > this._yearMin,
                            ),
                        ],
                    },
                ],
            };
        }
    }

    private _updateCustomDropdownDefinition(): void {
        if (this._yearMin && this._yearMax && this._monthMin && this._monthMax) {
            this._customValues = [
                {
                    id: CustomOption.AllYears,
                    name: this._lgTranslateService.translate(
                        "APP._Shared.Filters.TimeRange.Popup.AllYears",
                    ),
                    yearFrom: this._yearMin,
                    yearTo: this._yearMax,
                    monthFrom: this._monthMin,
                    monthTo: this._monthMax,
                    help: `${this._monthNames[this._monthMin - 1]} ${this._yearMin} - ${
                        this._monthNames[this._monthMax - 1]
                    } ${this._yearMax}`,
                    disabled: false,
                },
                this._addCustomValue(3, CustomOption.Last3Months, CustomType.Months),
                this._addCustomValue(6, CustomOption.Last6Months, CustomType.Months),
                this._addCustomValue(12, CustomOption.Last12Months, CustomType.Months),
                this._addCustomValue(2, CustomOption.Last2Years, CustomType.Years),
                this._addCustomValue(3, CustomOption.Last3Years, CustomType.Years),
                this._addCustomValue(4, CustomOption.Last4Years, CustomType.Years),
                this._addCustomValue(5, CustomOption.Last5Years, CustomType.Years),
            ];

            this._customDropdownDefinition = {
                empty:
                    this._lgTranslateService.translate(
                        "APP._Shared.Filters.TimeRange.Popup.Custom",
                    ) ?? "",
                groups: [
                    {
                        entries: [
                            ...this._customValues
                                .filter(value => value !== undefined)
                                .map(value => ({
                                    id: value?.id,
                                    name: value?.name,
                                    help: value?.help,
                                    disabled: value?.disabled,
                                })),
                        ],
                    },
                ],
            };
        }
    }

    private _addCustomValue(
        value: number,
        id: CustomOption,
        type: CustomType,
    ): ICustomDropdownEntry | undefined {
        if (!this._monthMin || !this._monthMax || !this._yearMin || !this._yearMax)
            return undefined;

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        // the start value is counted
        let startMonth = type === CustomType.Months ? currentMonth - value + 1 : currentMonth + 1;
        let startYear = type === CustomType.Months ? currentYear : currentYear - value;
        if (startMonth < 1) {
            startMonth = 12 + startMonth;
            startYear--;
        }

        let disabled = false;

        // values are adjusted to fit allowed range
        if (startYear > this._yearMax) {
            disabled = true;
        } else if (startYear === this._yearMax && startMonth > this._monthMax) {
            disabled = true;
        } else if (startYear < this._yearMin) {
            startYear = this._yearMin;
            startMonth = this._monthMin;
        } else if (startYear === this._yearMin && startMonth < this._monthMin) {
            startMonth = this._monthMin;
        }

        // if the option is equal to "All years" range, it's removed
        if ((startYear === this._yearMin, startMonth === this._monthMin && !disabled))
            return undefined;

        return {
            id,
            name: this._lgTranslateService.translate(
                type === CustomType.Months
                    ? "APP._Shared.Filters.TimeRange.Popup.LastNumMonths"
                    : "APP._Shared.Filters.TimeRange.Popup.LastNumYears",
                { num: value },
            ),
            yearFrom: startYear,
            monthFrom: startMonth,
            yearTo: disabled ? currentYear : this._yearMax,
            monthTo: disabled ? currentMonth : this._monthMax,
            help: disabled
                ? this._lgTranslateService.translate(
                      "APP._Shared.Filters.TimeRange.Popup.DisabledRange",
                  )
                : `${this._monthNames[startMonth - 1]} ${startYear} - ${
                      this._monthNames[this._monthMax - 1]
                  } ${this._yearMax}`,
            disabled,
        };
    }
}
