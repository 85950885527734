import { RegistryTenant, REGISTRY_TENANTS_LOOKUP } from "@codman/shared/assets";
import { AuthRegistryInfo, Tenants } from "./codman-user-authorization.types";

export const TENANT_LOOKUP = (() => {
    let lookup: { [registryId: string]: RegistryTenant } = {};

    const registryTenants: RegistryTenant[] = Object.getOwnPropertyNames(
        REGISTRY_TENANTS_LOOKUP,
    ) as RegistryTenant[];

    for (const tenantId of registryTenants) {
        lookup = REGISTRY_TENANTS_LOOKUP[tenantId].reduce((tenantLookup, registryId) => {
            tenantLookup[registryId] = tenantId;
            return tenantLookup;
        }, lookup);
    }

    return lookup;
})();

export function makeTenantsFromRegistries(registries: AuthRegistryInfo[]): Tenants {
    return registries.reduce((tenants, registry) => {
        const tRegistries = tenants[registry.tenant] ?? [];
        tenants[registry.tenant] = [...tRegistries, registry];
        return tenants;
    }, {} as Tenants);
}
