// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.range-filter-input * {
  box-sizing: border-box;
}
.range-filter-input__option-list-item {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  border-radius: var(--border-radius);
  padding: 0 4px;
  background: rgb(var(--base-40));
}
.range-filter-input__clear-btn {
  position: absolute;
  color: rgb(var(--gray-color-40));
  top: 0;
  right: 0;
  margin: 5px 5px 0 0;
  padding: 0;
  cursor: pointer;
  background: rgb(var(--white));
  border: none;
  display: none;
  box-shadow: 0px 1px rgb(var(--shadow-blur)) var(--shadow-gray-dark);
  border-radius: var(--border-radius);
}
.range-filter-input__clear-btn > .lg-icon {
  color: rgb(var(--base-50));
  display: block;
  width: 24px;
  height: 24px;
}
.range-filter-input:hover {
  border-color: rgb(var(--gray-color-40));
}
.range-filter-input:hover .range-filter-input__clear-btn {
  display: block;
}
.range-filter-input:hover .range-filter-input__clear-btn:hover {
  background-color: rgb(var(--base-10));
}`, "",{"version":3,"sources":["webpack://./libs/shared/ui-range-filter-input/src/lib/range-filter-input/range-filter-input.component.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;;AAKI;EACI,sBAAA;AAFR;AAKI;EACI,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,mCAAA;EACA,cAAA;EACA,+BAAA;AAHR;AAMI;EACI,kBAAA;EACA,gCAAA;EACA,MAAA;EACA,QAAA;EACA,mBAAA;EACA,UAAA;EACA,eAAA;EACA,6BAAA;EACA,YAAA;EACA,aAAA;EACA,mEAAA;EACA,mCAAA;AAJR;AAMQ;EACI,0BAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AAJZ;AAQI;EACI,uCAAA;AANR;AAQQ;EACI,cAAA;AANZ;AAQY;EACI,qCAAA;AANhB","sourcesContent":["@use \"definitions\" as *;\n\n:host {\n    width: 100%;\n}\n\n.range-filter-input {\n    * {\n        box-sizing: border-box;\n    }\n\n    &__option-list-item {\n        display: inline-block;\n        height: 20px;\n        line-height: 20px;\n        border-radius: var(--border-radius);\n        padding: 0 4px;\n        background: lg-color(--base-40);\n    }\n\n    &__clear-btn {\n        position: absolute;\n        color: lg-color(--gray-color-40);\n        top: 0;\n        right: 0;\n        margin: 5px 5px 0 0;\n        padding: 0;\n        cursor: pointer;\n        background: lg-color(--white);\n        border: none;\n        display: none;\n        box-shadow: 0px 1px lg-color(--shadow-blur) var(--shadow-gray-dark);\n        border-radius: var(--border-radius);\n\n        & > .lg-icon {\n            color: lg-color(--base-50);\n            display: block;\n            width: 24px;\n            height: 24px;\n        }\n    }\n\n    &:hover {\n        border-color: lg-color(--gray-color-40);\n\n        .range-filter-input__clear-btn {\n            display: block;\n\n            &:hover {\n                background-color: lg-color(--base-10);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
