import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Overlay } from "@angular/cdk/overlay";

import { LgOverlayService } from "@logex/framework/ui-core";

import { Range } from "@codman/shared/util-filters";
import {
    IYearPickerInitProps,
    IYearPickerSelection,
    SharedYearPickerComponent,
} from "@codman/shared/ui-year-picker";
import { OverlayBase } from "@codman/shared/ui-overlay";

@Component({
    selector: "codman-year-filter",
    templateUrl: "./year-filter.component.html",
    styleUrls: ["./year-filter.component.scss"],
})
export class YearFilterComponent
    extends OverlayBase<IYearPickerInitProps, IYearPickerSelection>
    implements OnDestroy
{
    @Input() min = 0;
    @Input() max = 0;
    @Input() range: Range = {
        min: null,
        max: null,
    };

    @Input() registry: string | undefined = undefined;
    @Input() subset: string | undefined = undefined;

    @Output() readonly didChange = new EventEmitter<Range>();

    constructor(
        _elementRef: ElementRef<HTMLElement>,
        _scrollDispatcher: ScrollDispatcher,
        _overlayService: LgOverlayService,
        _overlay: Overlay,
    ) {
        super(_elementRef, _scrollDispatcher, _overlayService, _overlay, SharedYearPickerComponent);
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showYearPicker($event?: Event): void {
        $event?.stopPropagation();

        if (this._popupActive) return;

        const result$ = this._showPopup(
            {
                min: this.min,
                max: this.max,
                selectedMin: this.range?.min,
                selectedMax: this.range?.max,
                singleYearSelection: false,
            },
            {
                positions: ["bottom-left", "bottom-center"],
            },
        );

        result$.subscribe({
            next: result => {
                this.range.min = result.selectedMin ?? null;
                this.range.max = result.selectedMax ?? null;
                localStorage.setItem(
                    "years",
                    JSON.stringify({
                        ...this.range,
                        registry: this.registry,
                        subset: this.subset,
                    }),
                );
                this._emitChange(this.range);
            },
            complete: () => this._hidePopup(),
        });

        this._popupActive = true;
    }

    _clear(): void {
        this._emitChange({
            min: null,
            max: null,
        });
        localStorage.removeItem("years");
    }

    private _emitChange(range: Range): void {
        this.didChange.emit(range);
    }
}
