import { Dashboards } from "@codman/shared/types";

export const ORG_ID_HEADER = "x-organization-id";

export type CodmanStructure = RegistryStructure[];

export interface RegistryStructure {
    name: string;
    tenant: string;
    applications: ApplicationStructure[];
}

export type ApplicationStructure =
    | DescriptivesStructure
    | IndicatorsStructure
    | ExplorationStructure
    | PatientsStructure
    | OverviewStructure
    | MedicineStructure;
/**
 * Descriptives
 */
export interface DescriptivesStructure {
    name: Dashboards.Descriptives;
    referenceProfiles: ReferenceProfile[];
}

export interface ReferenceProfile {
    id: string;
    lid: string;
    subsets: DescriptivesSubset[];
}

export interface DescriptivesSubset {
    id: number;
    label: string;
    years?: number[];
    episodes?: number[];
}

/**
 * Indicators
 */
export interface IndicatorsStructure {
    name: Dashboards.Indicators;
    pages: string[];
}

/**
 * Exploration
 */
export interface ExplorationStructure {
    name: Dashboards.Exploration;
    subsets: ExplorationSubset[];
}

export interface ExplorationSubset {
    id: string;
    pages: ExplorationPage[];
}

export interface ExplorationPage {
    id: string;
    sections: ExplorationSection[];
}

export interface ExplorationSection {
    id: string;
}

/**
 * Patients (like me)
 */
export interface PatientsStructure {
    name: Dashboards.PatientsLikeMe;
    subsets: PatientsSubset[];
}

export interface PatientsSubset {
    id: string;
    treatments: string[];
}

/**
 * Overview
 */
export interface OverviewStructure {
    name: Dashboards.Overview;
    registries: string[];
}

/**
 * Medicine
 * currently not on the BE
 */
export interface MedicineStructure {
    name: Dashboards.Medicine;
}

export const APPNAME_TO_APPINSTANCE_LOOKUP: { [appName in ApplicationStructure["name"]]: string } =
    {
        Descriptives: "ApplicationInstance.CodmanDescriptives.NL",
        Exploration: "ApplicationInstance.CodmanExploration.NL",
        Indicators: "ApplicationInstance.CodmanIndicators.NL",
        PatientsLikeMe: "ApplicationInstance.CodmanPatients.NL",
        Overview: "ApplicationInstance.CodmanOverview.NL",
        Medicine: "ApplicationInstance.CodmanMedicine.NL",
    };

export const APPNAME_TO_APPINSTANCE_CA_LOOKUP: {
    [appName in ApplicationStructure["name"]]: string;
} = {
    Descriptives: "ApplicationInstance.CodmanDescriptives.CA",
    Exploration: "ApplicationInstance.CodmanExploration.CA",
    Indicators: "ApplicationInstance.CodmanIndicators.CA",
    PatientsLikeMe: "ApplicationInstance.CodmanPatients.CA",
    Overview: "ApplicationInstance.CodmanOverview.CA",
    Medicine: "ApplicationInstance.CodmanMedicine.CA",
};
