import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, inject } from "@angular/core";
import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";

import { Observable, Subject } from "rxjs";

import { LgLayoutModule, LgPanelGridDefinition } from "@logex/framework/lg-layout";
import { LgTranslatePipe, LgTranslateService } from "@logex/framework/lg-localization";
import { IDropdownDefinition, LgButton, LgDropdownComponent } from "@logex/framework/ui-core";

import { IOverlayPopup } from "@codman/shared/ui-overlay";

export interface IYearPickerInitProps {
    min: number;
    max: number;
    selectedMin: number | null;
    selectedMax: number | null;
    singleYearSelection: boolean;
}

export interface IYearPickerSelection {
    selectedMin: number | null;
    selectedMax: number | null;
}

@Component({
    selector: "codman-year-picker",
    templateUrl: "./year-picker.component.html",
    styleUrls: ["./year-picker.component.scss"],
    standalone: true,
    imports: [CommonModule, LgLayoutModule, LgDropdownComponent, LgButton, LgTranslatePipe],
})
export class SharedYearPickerComponent
    implements OnInit, OnChanges, IOverlayPopup<IYearPickerInitProps, IYearPickerSelection>
{
    private _elementRef = inject(ElementRef);
    private _lgTranslateService = inject(LgTranslateService);

    // Template attributes
    _years: number[] = [];

    _gridDefinition: LgPanelGridDefinition = {
        columns: [{ size: 1, id: "top-left" }],
    };

    _customDropdownCurrent = 0;
    _customDropdownDefinition: IDropdownDefinition<number> | null = null;

    // Internal attributes
    private _selectionInProgress = false;
    private _lastSelectedYear = 0;

    _min = 0;
    _max = 0;
    _selectedMin: number | null = null;
    _selectedMax: number | null = null;
    _singleYearSelection = false;

    private readonly _selection$ = new Subject<IYearPickerSelection>();

    initialize(config: IYearPickerInitProps): Observable<IYearPickerSelection> {
        this._min = config.min;
        this._max = config.max;
        this._selectedMin = config.selectedMin;
        this._selectedMax = config.selectedMax;
        this._singleYearSelection = config.singleYearSelection;

        return this._selection$;
    }

    _updatePosition(position: ConnectedOverlayPositionChange): void {}

    _update(): void {
        const selection: IYearPickerSelection = {
            selectedMin: this._selectedMin,
            selectedMax: this._selectedMax,
        };

        this._selection$.next(selection);
        this._selection$.complete();
    }

    _cancel(): void {
        this._selection$.complete();
    }

    ngOnInit(): void {
        this._updateYears();
        this._updateCustomDropdownCurrentValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["min"] || changes["max"]) {
            this._updateYears();
        }
    }

    _selectionInProgressStart(e: MouseEvent, year: number): void {
        e.preventDefault();

        this._selectionInProgress = true;
        this._customDropdownCurrent = 0;

        this._setMaxAndMinWith(year);

        document.addEventListener("mouseup", this._onDocumentMouseUp);
        document.addEventListener("mousemove", this._onDocumentMouseMove);
    }

    _updateSelectionInProgress(year: number): void {
        if (!this._selectionInProgress) {
            return;
        }

        if (this._selectedMin === null || this._selectedMax === null) {
            this._setMaxAndMinWith(year);
            return;
        }

        if (year < this._selectedMin) {
            this._selectedMin = year;
        } else if (year > this._selectedMax) {
            this._selectedMax = year;
        } else {
            const diff = year - this._lastSelectedYear;

            if (diff > 0) {
                this._selectedMin = year;
            } else if (diff < 0) {
                this._selectedMax = year;
            }
        }

        this._lastSelectedYear = year;
    }

    _selectionInProgressEnd(): void {
        this._selectionInProgress = false;

        this._updateCustomDropdownCurrentValue();

        document.removeEventListener("mouseup", this._onDocumentMouseUp);
        document.removeEventListener("mousemove", this._onDocumentMouseMove);
    }

    _selectYear(year: number): void {
        this._setMaxAndMinWith(year);
    }

    _updateSelectionWithCustomRange(lastYears: number): void {
        this._selectedMax = this._max;
        this._selectedMin = this._selectedMax - (lastYears - 1);

        this._updateCustomDropdownCurrentValue();
    }

    private _updateCustomDropdownDefinition(): void {
        this._customDropdownDefinition = {
            empty:
                this._lgTranslateService.translate("APP._Shared.Filters.Years.Popup.Custom") ?? "",
            groups: [
                {
                    entries: this._years.map((year, index) => {
                        if (index === 0) {
                            return {
                                id: 1,
                                name:
                                    this._lgTranslateService.translate(
                                        "APP._Shared.Filters.Years.Popup.CurrentYear",
                                    ) ?? "",
                            };
                        }

                        const num = index + 1;
                        return {
                            id: index + 1,
                            name:
                                this._lgTranslateService.translate(
                                    "APP._Shared.Filters.Years.Popup.LastNumYears",
                                    { num },
                                ) ?? "",
                        };
                    }),
                },
            ],
        };
    }

    private _updateCustomDropdownCurrentValue(): void {
        this._customDropdownCurrent = 0;

        if (this._selectedMax === this._max && this._selectedMin != null) {
            this._customDropdownCurrent = this._selectedMax - (this._selectedMin - 1);
        }
    }

    private _setMaxAndMinWith(year: number): void {
        this._selectedMin = year;
        this._selectedMax = year;
        this._lastSelectedYear = year;
    }

    private _updateYears(): void {
        if ((!this._max && !this._min) || this._min > this._max) {
            this._years = [];
            return;
        }

        const length = this._max - this._min + 1;
        this._years = Array.from({ length }, (v, i) => this._min + i);

        this._updateCustomDropdownDefinition();
    }

    private _onDocumentMouseUp = (e: MouseEvent): void => {
        this._selectionInProgressEnd();
    };

    private _onDocumentMouseMove = (e: MouseEvent): void => {
        const target = e.target as HTMLElement;
        const rootElm = this._elementRef.nativeElement;

        if (!rootElm.contains(target)) {
            return;
        }

        const elmWithYear = target.hasAttribute("data-year")
            ? target
            : target.closest("*[data-year]");

        if (elmWithYear) {
            const year = +(elmWithYear.getAttribute("data-year") as string);
            this._updateSelectionInProgress(year);
        }
    };
}
