import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LG_FILTERSET_RENDERER_FACTORIES } from "@logex/framework/lg-filterset";
import { SharedRangeFilterInputComponent } from "@codman/shared/ui-range-filter-input";
import { SharedYearPickerComponent } from "@codman/shared/ui-year-picker";
import { YearFilterComponent } from "./year-filter/year-filter.component";
import {
    YearFilterRendererFactory,
    YearFilterRendererLineComponent,
} from "./year-filter/year-filter-renderer";
@NgModule({
    imports: [
        CommonModule,
        SharedRangeFilterInputComponent,
        UiCoreModule,
        LgLayoutModule,
        LgLocalizationModule,
        SharedYearPickerComponent,
    ],
    declarations: [YearFilterComponent, YearFilterRendererLineComponent],
    exports: [YearFilterComponent],
    providers: [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: YearFilterRendererFactory,
            multi: true,
        },
    ],
})
export class SharedYearFilterModule {}
