import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Overlay } from "@angular/cdk/overlay";

import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import { LgOverlayService } from "@logex/framework/ui-core";

import { Range } from "@codman/shared/util-filters";
import { OverlayBase } from "@codman/shared/ui-overlay";

import {
    HistogramPickerInitProps,
    HistogramPickerPopupComponent,
    HistogramPickerSelection,
} from "./histogram-picker-popup/histogram-picker-popup.component";

@Component({
    selector: "codman-histogram-filter",
    templateUrl: "./histogram-filter.component.html",
    styleUrls: ["./histogram-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistogramFilterComponent extends OverlayBase<
    HistogramPickerInitProps,
    HistogramPickerSelection
> {
    @Input() min = 0;
    @Input() max = 0;
    @Input() step = 1;
    @Input() range: Range = {
        min: null,
        max: null,
    };

    @Input() source!: () => Observable<number[]>;

    @Output() readonly didChange = new EventEmitter<Range>();

    constructor(
        _elementRef: ElementRef<HTMLElement>,
        _scrollDispatcher: ScrollDispatcher,
        _overlay: Overlay,
        _lgOverlayService: LgOverlayService,
    ) {
        super(
            _elementRef,
            _scrollDispatcher,
            _lgOverlayService,
            _overlay,
            HistogramPickerPopupComponent,
        );
    }

    _togglePopup(e: Range): void {
        const popupResult = this._showPopup({
            min: this.min || 0,
            max: this.max || 100,
            selectedMin: this.range.min,
            selectedMax: this.range.max,
            source: this.source(),
        });

        popupResult.pipe(take(1)).subscribe(result => {
            if (result !== null) {
                this._emitChange(result);
            }
            this._hidePopup();
        });
    }

    _clear(): void {
        this._emitChange({
            min: null,
            max: null,
        });
    }

    private _emitChange(range: Range): void {
        this.didChange.emit(range);
    }
}
