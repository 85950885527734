import { Component, inject } from "@angular/core";

import { RegistryRedirectService } from "../registry-redirect.service";

@Component({
    selector: "codman-registry-redirect",
    template: ``,
    styles: [],
})
export class RegistryRedirectComponent {
    private _redirectService = inject(RegistryRedirectService);

    constructor() {
        this._redirectService.redirect();
    }
}
