import { Observable, of } from "rxjs";

import { LgFilterSet } from "@logex/framework/lg-filterset";

import { ActiveFilters, FilterParam, Filters, MedicalItems } from "./data-access-api.types";
import { map, shareReplay, startWith } from "rxjs/operators";

export const fakeDefinition = {
    filters: {
        timeRange: {
            minYear: 2015,
            maxYear: 2020,
            minMonth: 1,
            maxMonth: 12,
        },
        bmi: {
            2: "Class I",
            4: "Class III",
        },
        clavienDindo: {
            $empty: true,
        },
    },
    isActive: (filterName: string): boolean => filterName === "bmi",
    onChanged: of({}),
    getFilterDefinition: (filterName: string): any => ({
        id: "bmi",
        filterType: "combo2",
    }),
    clearAll: (): void => {
        //
    },
};

export class DataAccessFiltersMockService {
    readonly filterDefinition$: Observable<LgFilterSet>;
    readonly activeFilters$: Observable<ActiveFilters>;

    constructor() {
        this.filterDefinition$ = of(<any>fakeDefinition).pipe(shareReplay(1));
        this.activeFilters$ = this.filterDefinition$.pipe(
            map(() => this.getActiveFilters(<any>fakeDefinition)),
            startWith({
                timeRange: { minYear: null, maxYear: null, minMonth: null, maxMonth: null },
            }),
        );
    }

    getActiveFilters(filterDefinition: LgFilterSet): ActiveFilters {
        const filters = filterDefinition.filters;
        if (filters == null) {
            console.warn("Attempting to get active filters when no filters are defined");
            return { timeRange: { minYear: null, maxYear: null, minMonth: null, maxMonth: null } };
        }

        return {
            timeRange: {
                maxYear: 2020,
                minYear: 2015,
                minMonth: 1,
                maxMonth: 12,
            },
            filters: [
                {
                    type: "category",
                    id: "age",
                    values: [1, 2],
                },
            ],
        };
    }

    setupFilterDefinition(filters: Filters, medicalParameters: MedicalItems): LgFilterSet {
        return <any>fakeDefinition;
    }

    getGeneralExportImageDescription(
        registryId: string,
        medicalParameters: MedicalItems,
        filters: FilterParam[],
        years?: Range,
    ): string {
        return "This is an image description including title, data source and applied filters";
    }
}
